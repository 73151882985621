import PageNotFound from './views/page-not-found.vue'
import Authentication from './views/authentication.vue'
import AuthenticationCallback from './views/authentication-callback.vue'

export default [
    {
        path: '/about',
        name: 'About',
        component: () => import('./views/about.vue'),
    },
    {
        path: '/support',
        name: 'Support',
        component: () => import('./views/support.vue'),
    },
    {
        path: '/auth',
        name: 'Authentication',
        component: Authentication,
    },
    {
        path: '/auth/callback',
        name: 'AuthenticationCallback',
        component: AuthenticationCallback,
    },

    {
        path: '*',
        component: PageNotFound,
    },
]
