import { formatCurrency, formatNumber } from '@/scripts/filters'
// import validation from '@/scripts/validation/validation'

export default {
    getIndustry(industryBase) {
        let industry = industryBase
        industry.buyerName = 'Buyer'
        industry.sellerName = 'Seller'
        industry.allocationName = 'Milestone'
        industry.allocationsName = 'Milestones'

        industry.allocationAmendmentDelta = function (allocation, amendment) {
            let changeList = []
            if (allocation.units !== amendment.units) {
                changeList.push({
                    field: 'litreage',
                    from: formatNumber(allocation.units),
                    to: formatNumber(amendment.units),
                })
            }
            if (allocation.unitCost !== amendment.unitCost) {
                changeList.push({
                    field: 'price per litre',
                    from: 'R' + formatCurrency(allocation.unitCost),
                    to: 'R' + formatCurrency(amendment.unitCost),
                })
            }
        }

        return industry
    },
}
