<template>
    <InputBase :label="label" :tooltip="tooltip" :error-messages="errorMessages" :dirty="dirty">
        <!-- TODO: We need to add the show/hide eyeball icon -->
        <div class="uk-grid uk-grid-collapse">
            <div class="uk-width-expand">
                <input
                    :name="name"
                    :value="value"
                    :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty }"
                    :type="inputType"
                    :disabled="disabled"
                    autocomplete="off"
                    class="uk-input"
                    @blur="$emit('blur', $event)"
                    @input="input($event)"
                />
            </div>
            <div>
                <a
                    :key="viewPassword"
                    class="eyeball"
                    @mouseenter="viewPassword = true"
                    @mouseleave="viewPassword = false"
                >
                    <div v-if="!viewPassword">
                        <img style="color: #999" width="20" height="20" src="/icons/eye-slash-solid.svg" uk-svg />
                    </div>
                    <div v-if="viewPassword" style="color: #999">
                        <img style="color: #999" width="18" height="18" src="/icons/eye-solid.svg" uk-svg />
                    </div>
                </a>
            </div>
        </div>
    </InputBase>
</template>

<script>
import InputBase from './input-base'
export default {
    name: 'GL-PasswordInput',
    components: {
        InputBase,
    },
    data() {
        return {
            dirty: false,
            viewPassword: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        inputType() {
            if (this.viewPassword) {
                return 'text'
            }
            return 'password'
        },
    },
    methods: {
        input(event) {
            this.dirty = true
            this.$emit('input', event.target.value)
        },
    },
}
</script>
<style lang="scss" scoped>
.eyeball {
    width: 2.5rem;
    height: 40px;
    background-color: #f2f3f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
}
</style>
