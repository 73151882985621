<template>
    <ModalBase ref="modal">
        <div :key="refreshCount">
            <h4>Create Transaction</h4>
            <p>
                We have identified a business account associated with your profile. <br />
                <br />
                In what capacity would you like to create this transaction? <br />
                <br />
                Please be advised this action cannot be changed during this transaction.
            </p>
            <div v-if="$store.getters['context/context'] === '__loading__'">
                <div class="uk-flex uk-flex-center uk-margin">
                    <GL-Spinner />
                </div>
            </div>
            <div v-if="$store.getters['context/context'] !== '__loading__'" class="uk-flex uk-grid uk-flex-center">
                <div class="">
                    <!-- <div class="uk-flex uk-flex-center uk-margin-small-bottom">
                        <label class="uk-text-bold"> Personal Account </label>
                    </div> -->
                    <div class="uk-flex uk-flex-center">
                        <CardButton
                            data-id="link_content_product"
                            data-text="create transaction modal | personal button click"
                            text="Personal"
                            iconPath="user.svg"
                            :selected="contextType === 'User'"
                            @select="$store.dispatch('context/contextSelectPersonal')"
                        />
                    </div>
                </div>
                <div class="">
                    <!-- <div class="uk-flex uk-flex-center uk-margin-small-bottom">
                        <label class="uk-text-bold"> Business Account </label>
                    </div> -->
                    <div class="uk-flex uk-flex-center">
                        <CardButton
                            data-id="link_content_product"
                            data-text="create transaction modal | business button click"
                            text="Business"
                            iconPath="briefcase-solid.svg"
                            :selected="contextType === 'Organization'"
                            @select="$store.dispatch('context/contextSelectBusiness')"
                        />
                    </div>
                </div>
            </div>
            <div class="uk-grid uk-grid-small uk-flex-wrap-reverse uk-margin-top">
                <div class="uk-width-1-2@m">
                    <GL-Button
                        data-id="link_content_product"
                        data-text="create transaction modal | cancel button click"
                        class="uk-margin-small-top uk-width-expand"
                        text="Cancel"
                        type="default"
                        @click="cancel()"
                    />
                </div>
                <div class="uk-width-1-2@m">
                    <GL-Button
                        data-id="link_content_product"
                        data-text="create transaction modal | continue button click"
                        class="uk-margin-small-top uk-width-expand"
                        text="Continue"
                        disabledText="Continue"
                        type="primary"
                        @click="submit()"
                        :disabled="!createTransactionEnabled"
                        :uk-tooltip="createTransactionTooltip"
                    />
                </div>
            </div>
        </div>
    </ModalBase>
</template>
<script>
import CardButton from '@/modules/common/components/input/card-button'
import ModalBase from '@/modules/common/components/modal-base'
export default {
    components: { ModalBase, CardButton },
    data() {
        return {
            otp: '',
            refreshCount: 0,
            onsubmit: () => {
                console.error('no onsubmit callback was provided')
            },
            onclose: () => {
                console.error('no onclose callback was provided')
            },
            onresend: () => {
                console.error('no onresend callback was provided')
            },
            destination: '',
            resendInProgress: false,
            resendComplete: false,
            resendError: null,
        }
    },
    computed: {
        contextType() {
            return this.$store.getters['context/context'].__typename
        },
        createTransactionEnabled() {
            if (this.$store.getters['context/context'] === '__loading__') {
                return false
            }
            if (this.$store.getters['context/contextVerification']) {
                return false
            }
            return true
        },
        createTransactionTooltip() {
            if (this.$store.getters['context/contextVerification']) {
                return this.$store.getters['context/contextVerification'].message
            }
            return null
        },
    },
    methods: {
        show() {
            this.$store.commit('user/setUserField', { key: 'otp', value: '' })
            this.refreshCount++
            this.$refs.modal.show()

            let _this = this
            return new Promise(resolve => {
                _this.onsubmit = () => {
                    resolve({ submit: true })
                }
                _this.onclose = () => {
                    resolve({ submit: false })
                }
            })
        },
        submit() {
            try {
                this.onsubmit()
            } catch (error) {
                this.$handleError(error)
            } finally {
                this.$refs.modal.hide()
            }
        },
        cancel() {
            try {
                this.onclose()
            } catch (error) {
                this.$handleError(error)
            } finally {
                this.$refs.modal.hide()
            }
        },
    },
}
</script>
