import { render, staticRenderFns } from "./gl-info-item.vue?vue&type=template&id=0ed9bc53&scoped=true&"
import script from "./gl-info-item.vue?vue&type=script&lang=js&"
export * from "./gl-info-item.vue?vue&type=script&lang=js&"
import style0 from "./gl-info-item.vue?vue&type=style&index=0&id=0ed9bc53&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed9bc53",
  null
  
)

export default component.exports