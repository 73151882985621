var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalBase',{ref:"cancel-modal"},[_c('div',[_c('h4',[_vm._v("Save Changes?")]),_c('GL-ErrorAlert',{attrs:{"errorMessage":_vm.errorMessage}}),_c('div',{staticClass:"uk-margin-top"},[_c('p',{staticClass:"uk-padding"},[_vm._v("Would you like us to save this transaction for future use?")]),_c('hr'),_c('div',{staticClass:"uk-flex uk-flex-between uk-flex-right uk-flex-wrap-reverse"},[_c('GL-Button',{class:{
                        'uk-margin-small-right': _vm.$screenLarger('small'),
                        'uk-width-1-1': _vm.$screenSmaller('small'),
                    },attrs:{"text":"Cancel","type":"default uk-button-small","name":"cancel-cancel-transaction"},on:{"click":_vm.hide}}),_c('div',{class:{ 'uk-width-1-1': _vm.$screenSmaller('small') }},[_c('GL-Button',{class:{
                            'uk-margin-small-right': _vm.$screenLarger('small'),
                            'uk-width-1-1': _vm.$screenSmaller('small'),
                            'uk-margin-small': _vm.$screenSmaller('small'),
                        },attrs:{"type":"secondary uk-button-small","name":"cancel-do-not-save-transaction","text":"no","loading":_vm.loading},on:{"click":_vm.deleteTransaction}}),_c('GL-Button',{class:{
                            'uk-margin-small-right': _vm.$screenLarger('small'),
                            'uk-width-1-1': _vm.$screenSmaller('small'),
                            'uk-margin-small': _vm.$screenSmaller('small'),
                        },attrs:{"type":"primary uk-button-small","text":"yes","name":"cancel-save-transaction"},on:{"click":_vm.saveTransaction}})],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }