import { formatCurrency } from '@/scripts/filters'
import validation from '@/scripts/validation/validation'

import IndustryMining from './service-industry-mining'
import IndustryFuel from './service-industry-fuel'

// The point of the Industry Service is simply to have a single shared entry point where
// we can set all the industry specific properties
export default {
    getIndustry(industryType) {
        // This is the base industry object which shares any properties across all industries
        // /////////////////// START OF BASE INDUSTRY
        const industryDisplayNameMap = {
            AGRICULTURE_LIVESTOCK_GAME: 'Agriculture, Livestock & Game',
            ART_ANTIQUES_COLLECTIBLES: 'Art, Antiques & Collectibles',
            BUSINESS_SALE_BROKING: 'Business Sale & Broking',
            VEHICLES_WATERCRAFT: 'Cars, Bikes & Watercraft',
            CELLPHONES_COMPUTERS: 'Cellphones & Computers',
            CONSTRUCTION: 'Construction',
            CONTRACT_WORK_FREELANCING: 'Contract Work & freelancing',
            FUEL: 'Diesel, Petroleum & Biofuel (Local)',
            FUEL_INTERNATIONAL: 'Diesel, Petroleum & Biofuel (Cross-Border)',
            GENERAL_GOODS_SERVICES: 'General Goods & Services',
            DONATIONS_TRUSTS: 'Donations & Trusts',
            FILMS_PRODUCTION: 'Films & Production',
            HOLIDAY_LETS_DEPOSITS: 'Holiday Lets & Deposits',
            INVESTMENTS_EXITS: 'Investments & Exits',
            MINING: 'Mining, Metals & Minerals',
            LEASES_RENTAL_DEPOSITS: 'Rental Deposits',
            USED_PARTS: 'USED_PARTS',
            SOFTWARE_DEV_WEB_DOMAINS: 'Web Domain Purchases & Transfers',
            WEDDINGS_FUNCTIONS: 'Weddings & Functions',
            PROPERTY: 'Residential & Commercial Property Sales',
        }
        let industry = {
            displayName: industryDisplayNameMap[industryType],
            buyerName: 'Buyer',
            sellerName: 'Seller',
            transactionName: 'Transaction',
            allocationName: 'Milestone',
            allocationsName: 'Milestones',
            allocationStatus: function (allocation) {
                if (allocation.state === 'CREATED') {
                    return {
                        type: 'info',
                        icon: 'info-circle-solid.svg',
                        text: `Delivery of ${this.allocationName.toLowerCase()} has not started`,
                    }
                }
                if (allocation.state === 'INITIATED') {
                    return {
                        type: 'primary',
                        icon: 'arrow-alt-circle-right-solid.svg',
                        text: `Delivery is underway`,
                    }
                }
                if (allocation.state === 'DELIVERED') {
                    return {
                        type: 'success',
                        icon: 'check-circle-solid.svg',
                        text: `${this.allocationName} has been accepted`,
                    }
                }
                if (allocation.state === 'DISPUTED') {
                    return {
                        type: 'danger',
                        icon: 'times-circle-solid.svg',
                        text: `${this.allocationName} has been disputed`,
                    }
                }
                return null
            },
            allocationValues: function (allocation) {
                const values = []
                values.push({
                    name: 'value',
                    label: `${this.allocationName} Value`,
                    value: 'R' + formatCurrency(allocation.value),
                })
                values.push({
                    name: 'deliver',
                    label: 'days to deliver',
                    value: allocation.daysToDeliver,
                })
                values.push({
                    name: 'inspect',
                    label: 'days to inspect',
                    value: allocation.daysToInspect,
                })

                // Expected Completion Date
                if (allocation.state === 'INITIATED') {
                    let ecd = new Date(allocation.initiatedDate)
                    ecd.setDate(ecd.getDate() + allocation.daysToDeliver)
                    values.push({
                        name: 'inspect',
                        label: 'Expected Completion Date',
                        value: ecd.toLocaleDateString(),
                    })
                }

                return values
            },
            allocationAmendableValues(allocation, transaction) {
                const allocationName = transaction.allocations.length > 1 ? this.allocationName : this.transactionName
                const amendableValues = []
                amendableValues.push({
                    modeltarget: 'value',
                    value: allocation.value,
                    component: 'GL-CurrencyInput',
                    componentprops: {
                        label: `${allocationName} Value`,
                        showSpin: true,
                        class: 'uk-width-1-1',
                    },
                    validations: [
                        validation.list.required(`Please provide the ${allocationName.toLowerCase()} value`),
                        validation.list.max_value(
                            `The ${allocationName.toLowerCase()} value cannot exceed the original value`,
                            allocation.value,
                        ),
                        validation.list.min_value(
                            `The ${allocationName.toLowerCase()} value cannot fall below R${formatCurrency(allocation.calculation.fee)}`,
                            allocation.calculation.fee,
                        ),
                    ],
                })
                amendableValues.push({
                    modeltarget: 'daysToDeliver',
                    value: allocation.daysToDeliver,
                    component: 'GL-NumberInput',
                    componentprops: {
                        label: 'DAYS FOR DELIVERY',
                        showSpin: true,
                        class: 'uk-width-1-2@s',
                    },
                    validations: [
                        validation.list.required('Please provide the days to deliver'),
                        validation.list.min_value(
                            `The days to deliver cannot be less than the orginal value`,
                            allocation.daysToDeliver,
                        ),
                    ],
                })
                amendableValues.push({
                    modeltarget: 'daysToInspect',
                    value: allocation.daysToInspect,
                    component: 'GL-NumberInput',
                    componentprops: {
                        label: 'DAYS FOR INSPECTION',
                        showSpin: true,
                        class: 'uk-width-1-2@s',
                    },
                    validations: [
                        validation.list.required('Please provide the days to deliver'),
                        validation.list.min_value(
                            `The days to deliver cannot be less than the orginal value`,
                            allocation.daysToInspect,
                        ),
                    ],
                })

                return amendableValues
            },
            allocationAmendmentDelta(allocation, amendment) {
                let changeList = []
                if (amendment.value < allocation.value) {
                    changeList.push(
                        `The value was changed from R${formatCurrency(allocation.value)} to R${formatCurrency(
                            amendment.value,
                        )}`,
                    )
                    changeList.push(
                        `REFUND TO ${industry.buyerName}: R${formatCurrency(allocation.value - amendment.value)}`,
                    )
                }
                if (amendment.daysToDeliver > allocation.daysToDeliver) {
                    changeList.push(
                        `The days to deliver was changed from ${allocation.daysToDeliver} to ${amendment.daysToDeliver}`,
                    )
                }
                if (amendment.daysToInspect > allocation.daysToInspect) {
                    changeList.push(
                        `The days to inspect was changed from ${allocation.daysToInspect} to ${amendment.daysToInspect}`,
                    )
                }
                return changeList
            },
        }
        // \\\\\\\\\\\\\\\\\\ END OF BASE INDUSTRY

        const overrideMap = {
            MINING: IndustryMining,
            FUEL: IndustryFuel,
        }

        industry = overrideMap[industryType] ? overrideMap[industryType].getIndustry(industry) : industry

        return industry
    },
}
