<template>
    <div class="uk-card uk-card-default uk-card-body">
        <h1 class="uk-text-center">Welcome to Standard Bank's Escrow</h1>
        <div class="uk-grid">
            <div class="uk-width-1-2@m">
                <h4>To use the escrow service, please acknowledge and consent to the following</h4>
                <form id="form_register" data-frmcontainer="Complete Your Registration" @submit.prevent="">
                    <div>
                        <GL-CheckboxInput
                            class="uk-margin"
                            v-model="terms"
                            data-id="link_content_content link"
                            data-text="register your account | standard bank escrow terms and conditions radio button click"
                        >
                            <template v-slot:label-suffix>
                                I agree to
                                <a
                                    href="/documents/Standard_Banks_Escrow_Terms_and_Conditions_13_11_2020.pdf"
                                    target="_blank"
                                >
                                    Standard Bank's Escrow terms and conditions
                                </a>
                            </template>
                        </GL-CheckboxInput>
                        <GL-CheckboxInput
                            text="I agree I am 18 years or older"
                            class="uk-margin"
                            v-model="age"
                            data-id="link_content_content link"
                            data-text="register your account | agree I am 18 years radio button click"
                        />
                        <GL-CheckboxInput
                            text="I agree that I am using Escrow for local transactions only"
                            class="uk-margin"
                            v-model="local"
                            data-id="link_content_content link"
                            data-text="register your account | using escrow for transactions radio button click"
                        />

                        <GL-Button
                            type="primary"
                            text="Confirm"
                            @click="confirm(terms, age, local)"
                            disabled-text="Confirm"
                            :disabled="terms !== true || age !== true || local !== true"
                            data-id="link_content_content link"
                            data-text="confirm your account | confirm button click"
                        />
                    </div>
                </form>
            </div>
            <div class="uk-width-1-2 uk-visible@m">
                <h4>Why Standard Bank Escrow</h4>
                <h2>
                    <GL-Icon path="clock-light.svg" :size="2.5" class="uk-margin-right register-reason-icon" />
                    Standard Bank's Escrow is fast
                </h2>
                <h2>
                    <GL-Icon path="check.svg" :size="2.5" class="uk-margin-right register-reason-icon" />
                    All users are verified
                </h2>
                <h2>
                    <GL-Icon path="comment-light.svg" :size="2.5" class="uk-margin-right register-reason-icon" />
                    Notifications are sent to each party
                </h2>
                <h2>
                    <GL-Icon path="lock-light.svg" :size="2.5" class="uk-margin-right register-reason-icon" />
                    Your transactions are safe and secure
                </h2>
                <h2>
                    <GL-Icon path="laptop-light.svg" :size="2.5" class="uk-margin-right register-reason-icon" />
                    The entire process is performed online
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Consent',
    data() {
        return {
            terms: false,
            age: false,
            local: false,
        }
    },
    methods: {
        confirm(terms, age, local) {
            this.$store.dispatch('user/setConsent', {
                terms: terms,
                age: age,
                local: local,
            })
        },
    },
}
</script>
