<template>
    <div class="bordered-card document-card">
        <div v-if="!showVisibilitySettings && allowDelete" class="close" @click="deleteDocument">
            <GL-Icon
                v-if="document.state === '__SAVED__' || document.state === '__FAILED__'"
                path="trash.svg"
                :size="1"
            />
            <!--            <GL-Spinner v-else :size="1" />-->
            <span v-else-if="document.state === '__DELETING__'" uk-spinner="ratio: 0.5" />
        </div>
        <div v-if="showVisibilitySettings" class="content-area">
            <DocumentVisibilityEdit @done="showVisibilitySettings = false" :index="index" />
        </div>
        <div v-else class="content-area uk-flex uk-flex-column uk-flex-between">
            <div class="uk-text-center">
                <div class="content-placeholder">
                    <div v-if="document.state === '__CREATING__'" class="uk-margin-top">
                        <GL-Spinner :size="4" />
                    </div>
                    <div v-else-if="!image" class="uk-padding-small">
                        <GL-Icon :path="icon" :size="4" />
                    </div>
                    <div v-else-if="image" class="image-box">
                        <img :src="document.file.uri" alt="Uploaded Image" uk-img />
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-margin-small">
                    <div class="uk-text-meta">Filename</div>
                    <div class="uk-text-truncate">
                        {{ filename }}
                    </div>
                </div>
                <div v-if="document.state === '__FAILED__'">
                    <div class="uk-text-meta">File Status</div>
                    <div>
                        <span class="uk-text-danger uk-margin-small-right">UPLOAD FAILED</span>
                    </div>
                </div>
                <div v-else-if="allowVisibilityEdit">
                    <div class="uk-text-meta">Visibility</div>
                    <div class="uk-flex uk-flex-between uk-flex-bottom">
                        <div v-if="!allowedParties || allowedParties.length === parties.length">
                            <span>Everyone</span>
                        </div>
                        <div v-else>
                            <span v-for="partyId in allowedParties" :key="partyId" class="uk-badge visibility-badge">{{
                                partyRole(partyId) | transactionRoleDisplay
                            }}</span>
                        </div>
                        <div class="uk-text-danger">
                            <a @click="showVisibilitySettings = true" class="edit-anchor">Edit</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DocumentVisibilityEdit from './documents-visibility-edit'
export default {
    name: 'DocumentCard.vue',
    components: { DocumentVisibilityEdit },
    props: {
        updating: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showVisibilitySettings: false,
            icons: {
                xls: 'file-excel-regular.svg',
                xlsx: 'file-excel-regular.svg',
                pdf: 'file-pdf-regular.svg',
                doc: 'file-word-regular.svg',
                docx: 'file-word-regular.svg',
                ppt: 'file-powerpoint-regular.svg',
                pptx: 'file-powerpoint-regular.svg',
                unknown: 'file-regular.svg',
            },
        }
    },
    computed: {
        document() {
            return this.$store.state.txCreate.documents.documents[this.index]
        },
        image() {
            if (this.document.file && this.document.file.mimetype) {
                return this.document.file.mimetype && this.document.file.mimetype.split('/')[0] === 'image'
            }
            return false
        },
        owner() {
            return this.$store.state.transaction.transaction.metadata.activeParty.owner
        },
        allowDelete() {
            return (
                this.$store.state.transaction.transaction.metadata.activeParty.owner &&
                (this.$store.state.transaction.transaction.state === 'CREATED' ||
                    this.$store.state.transaction.transaction.state === 'INVITATION_SENT')
            )
        },
        allowVisibilityEdit() {
            return this.$store.state.transaction.transaction.metadata.activeParty.role === 'AGENT' && this.owner
        },
        filename() {
            return this.document.file.filename || this.document.file.name
        },
        fileExtension() {
            if (this.document.file) {
                const ext = this.filename.split('.')
                if (ext.length < 2) {
                    return 'unknown'
                } else {
                    return ext[ext.length - 1].toLowerCase()
                }
            }
            return 'unknown'
        },
        icon() {
            const icon = this.icons[this.fileExtension]
            if (icon) {
                return icon
            }
            return this.icons.unknown
        },
        allowedParties() {
            return this.document.file.allowedParties
        },
        parties() {
            return this.$store.state.transaction.transaction.parties
        },
    },
    methods: {
        deleteDocument() {
            this.$store.dispatch('txCreate/deleteDocument', this.document)
        },
        partyRole(partyId) {
            const party = this.parties.find(party => party.id === partyId)
            if (party) {
                return party.role
            }
            return 'role'
        },
    },
}
</script>

<style lang="scss" scoped>
.visibility-edit-box {
    z-index: 1000;
    position: absolute;
}

.content-area {
    margin: 0.5rem;
    width: 230px;
    min-height: 230px;
}
.image-box {
    width: 100%;
    height: 110px;
}
.image-box img {
    padding-left: 0 !important;
    object-fit: fill;
    max-width: 100%;
    max-height: 100%;
}
.edit-anchor {
    color: $secondary-blue;
    &:hover {
        color: lighten($secondary-blue, 10%);
    }
}

.document-card {
    position: relative;
    margin: 2px;
}

.close {
    position: absolute;
    top: -2px;
    right: 8px;
    color: $grey;
    &:hover {
        cursor: pointer;
        color: $secondary-blue;
    }
}
.content-placeholder {
    height: 120px;
}
.visibility-badge {
    background-color: $grey;
    margin-left: 2px;
}
</style>
