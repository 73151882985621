<template>
    <div>
        <div class="wrapper">
            <div
                v-if="consentRequired"
                class="uk-overlay-default uk-position-cover uk-position-z-index uk-position-fixed"
            >
                <div class="uk-position-center">
                    <Consent />
                </div>
            </div>
            <Navbar />
            <Notification />
            <slot />
            <div class="push"></div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/modules/common/components/navbar'
import Notification from '@/modules/common/components/notification'
import Footer from '@/modules/common/components/footer'
import Vuex from 'vuex'
import Consent from '@/modules/common/components/consent.vue'

export default {
    name: 'Default',
    components: {
        Consent,
        Navbar,
        Notification,
        Footer,
    },
    data() {
        return {
            consentRequired: false,
        }
    },
    computed: {
        ...Vuex.mapState({
            user: state => state.user.user,
        }),
    },
    watch: {
        user(newUser) {
            this.consentRequired = false
            if (
                newUser.settings.consent.local == null ||
                newUser.settings.consent.age == null ||
                newUser.settings.consent.terms == null
            ) {
                this.consentRequired = true
            }
        },
    },
}
</script>

<style scoped>
body {
    min-height: 100vh;
}
.wrapper {
    min-height: 100vh;
    margin-bottom: -10.55rem;
}
.push {
    height: 14rem;
}
</style>
