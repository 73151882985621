<template>
    <div class="uk-card uk-card-default uk-card-body uk-animation-slide-top-small">
        <div v-if="complete" class="progress-tag completed">Completed</div>
        <div v-if="declined" class="progress-tag cancelled">Declined</div>
        <div v-if="canceled" class="progress-tag cancelled">Cancelled</div>
        <div v-if="inProgress" class="progress-tag">STEP {{ stateNumber }}/{{ 7 }}</div>

        <div class="uk-text-right" style="margin-top: -1rem">
            <div v-if="transaction.metadata.activeParty.role === 'BUYER'" class="uk-label">BUYING</div>
            <div v-if="transaction.metadata.activeParty.role === 'SELLER'" class="uk-label">SELLING</div>
        </div>
        <h2 class="heading-muted-1">{{ transaction.title }}</h2>
        <span class="heading-border"></span>

        <div v-if="complete" class="progress-heading completed">This transaction has been completed</div>
        <div v-if="declined" class="progress-heading cancelled">This transaction has been declined</div>
        <div v-if="canceled" class="progress-heading cancelled">This transaction has been cancelled</div>
        <div v-if="inProgress" class="progress-heading">
            Step {{ stateNumber }} - {{ transaction | transactionStateDisplay }}
        </div>

        <progress class="uk-progress" :value="stateNumber" max="7">state.step</progress>
        <div uk-grid>
            <div class="uk-width-1-2@s">
                <div class="beneficiary-amount">
                    <span class="beneficiary-currency">R</span>{{ transaction.calculation.baseValue | currency }}
                </div>
                <span class="small-detail-text uk-text-uppercase">Transaction value</span>
            </div>
            <div class="uk-width-1-2@s">
                <h3 class="card-subheading">
                    <!-- Split is to work around an issue IE has if the date string is not following RFC or ISO standards -->
                    {{
                        new Date(transaction.createdAt.split(' ')[0]).toLocaleDateString('en-ZA', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })
                    }}
                </h3>
                <span class="small-detail-text uk-text-uppercase">Date created</span>
            </div>
        </div>
        <div class="uk-margin-large-top">
            <div class="uk-grid">
                <div class="uk-width-1-2">
                    <GL-Button
                        data-id="link_content_product"
                        data-text="transaction card | view button click"
                        type="primary"
                        text="View"
                        @click="navigateTransaction"
                        :uk-tooltip="disabledTooltip"
                        disabledText="View"
                        :disabled="disabled"
                    />
                </div>
                <div v-if="transaction.state === 'FUNDS_RELEASED'" class="uk-width-1-2 uk-text-right">
                    <GL-Button
                        data-id="link_content_product"
                        data-text="transaction card | view invoice button click"
                        type="default"
                        text="View Invoice"
                        @click="viewInvoice"
                        disabled-test="View Invoice"
                        :loading="loadingInvoice"
                        :diabled="loadingInvoice"
                    />
                </div>
                <div v-if="transaction.state === 'CREATED'" class="uk-width-1-2 uk-text-right">
                    <GL-Button
                        data-id="link_content_product"
                        data-text="transaction card | delete button click"
                        type="danger"
                        text="Delete"
                        @click="deleteTransaction"
                        :loading="loadingDelete"
                        loadingText="Deleting"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/scripts/api/api'
import downloadFileFromURI from '@/scripts/download-file-from-uri'
export default {
    name: 'TransactionCard',
    props: {
        transaction: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledTooltip: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loadingDelete: false,
            loadingInvoice: false,
        }
    },
    methods: {
        navigateTransaction() {
            if (this.disabled) {
                return
            }
            this.$router.push('/transaction/' + this.transaction.id)
        },
        async viewInvoice() {
            this.loadingInvoice = true
            try {
                const invoiceData = await api.transaction.transactionViewInvoice(this.transaction.id)
                downloadFileFromURI(invoiceData.filename, invoiceData.uri)
            } catch (e) {
                this.error = this.$handleError(e)
            } finally {
                this.loadingInvoice = false
            }
        },
        async deleteTransaction() {
            this.loadingDelete = true
            await this.$store.dispatch('transaction/transactionDelete', {
                id: this.transaction.id,
            })
            await this.$store.dispatch('transaction/transactionListFetch')
            this.loadingDelete = false
        },
    },
    computed: {
        stateNumber() {
            const stateToNumber = {
                INVITATION_SENT: 1,
                ACCEPTED: 2,
                FUNDS_DEPOSITED: 2,
                FUNDS_RECEIVED: 3,
                INITIATED: 4,
                DELIVERED: 5,
                FUNDS_RELEASED: 6,
            }
            return stateToNumber[this.transaction.state]
        },
        progress() {
            return {
                width: ((this.stateNumber / 6) * 100).toString() + '%',
            }
        },
        inProgress() {
            return !this.complete && !this.declined && !this.canceled
        },
        complete() {
            return this.transaction.state === 'FUNDS_RELEASED'
        },
        declined() {
            return this.transaction.state === 'DECLINED'
        },
        canceled() {
            return this.transaction.state === 'CANCELED'
        },
    },
}
</script>
<style lang="scss" scoped>
.uk-card {
    padding: 35px 20px 30px;
    color: #07274a;
    background-color: $white;
    vertical-align: top;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
    .card-icon {
        display: inline-block;
        margin-bottom: 15px;
    }
    .card-heading {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        color: $light-blue-grey;
        padding-bottom: 10px;
    }
    .card-subheading {
        font-size: 25px;
        color: $dark-blue;
        margin: 25px 0 0;
    }
    h6 {
        text-transform: uppercase;
        color: $grey;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 10px;
    }
}
.uk-card-default[disabled] {
    opacity: 0.5;
}

.uk-label {
    background-color: white;
    color: grey;
    border-color: grey;
    border-style: solid;
    border-width: 1px;
}
.uk-button {
    width: 100%;
    min-width: 100px;
}

.current-trades-panel {
    align-items: center;
    padding-bottom: 15px;
    .uk-tab {
        li {
            a {
                .tab-active-border {
                    bottom: -15px;
                }
            }
        }
    }
}
</style>
