<template>
    <div>
        <div class="uk-flex uk-flex-between uk-flex-wrap" :class="{ 'uk-margin': $screenSmaller('small') }">
            <div><h4>Transaction Details</h4></div>
            <div>
                <GL-Button
                    v-if="showEdit"
                    text="Edit"
                    type="primary-outlined"
                    @click="$router.push(`/transaction/${transaction.id}/details`)"
                />
            </div>
        </div>
        <component :is="detailsComponent" />
    </div>
</template>
<script>
import mixinTransaction from '@/modules/common/mixin-transaction'
export default {
    mixins: [mixinTransaction],
    props: {
        showEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailsComponent() {
            // const componentMap = {
            //     FUEL: 'fuel',
            //     MINING: 'mining',
            //     VEHICLES_WATERCRAFT: 'vehicles',
            //     CELLPHONES_COMPUTERS: 'electronics',
            //     SOFTWARE_DEV_WEB_DOMAINS: 'web',
            //     PROPERTY: 'property',
            //     AGRICULTURE_LIVESTOCK_GAME: 'agriculture',
            //     MERGERS_ACQUISITIONS: 'mergers',
            // }
            const industry = 'general'
            return () => import(`./overview-details-${industry}`)
        },
    },
}
</script>
