<template>
    <div v-if="visible" class="uk-animation-slide-top">
        <div class="outcome-notification" :class="{ declined: type === 'failure' }">
            <div class="uk-container uk-padding">
                <div class="notification-details">
                    <div uk-grid>
                        <div class="uk-width-4-5">
                            <div uk-grid>
                                <div class="uk-width-auto">
                                    <GL-Icon path="check-circle-solid.svg" :size="5" />
                                </div>
                                <div class="uk-width-expand">
                                    <h3>{{ title }}</h3>
                                    <p>{{ body }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-5 uk-text-right">
                            <a class="close" @click="hide">
                                <GL-Icon path="times-circle-solid.svg" :size="2" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            type: 'success',
            title: 'Title',
            body: 'body',
        }
    },
    mounted() {
        let _this = this
        this.$globalEventOn('notification', payload => {
            window.scroll(0, 0)
            _this.visible = true
            _this.title = payload.title
            _this.type = payload.type
            _this.body = payload.body
        })
        this.$globalEventOn('notificationHide', () => (_this.visible = false))
    },
    computed: {
        icon() {
            if (this.type === 'danger') {
                return 'times.svg'
            }
            return 'check-solid.svg'
        },
        classComputed() {
            if (this.type === 'danger') {
                return 'notification-danger'
            }
            return 'notification-success'
        },
    },
    methods: {
        hide() {
            this.visible = false
        },
    },
    watch: {
        '$route.fullPath'() {
            this.hide()
        },
    },
}
</script>
<style lang="scss" scoped>
.close {
    color: white;
}
.outcome-notification {
    margin-bottom: 2rem;
    margin-top: -2rem;
    background: linear-gradient(90deg, $green 0%, #7fd37f 100%);
    color: $white;
    h3 {
        color: $white;
    }
    &.declined {
        background: linear-gradient(90deg, #d95450 0%, #df6c68 100%);
    }
}
</style>
