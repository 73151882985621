<template>
    <div class="uk-margin-large-top">
        <div class="uk-margin uk-text-center">
            <div class="uk-text-small uk-text-uppercase">
                <p>
                    Is this a normal transaction with a once off payment<br />
                    or does the transaction require multiple payment milestones?
                </p>
            </div>

            <div class="uk-flex uk-flex-center uk-flex-wrap create-tour-step-2">
                <CardButton
                    text="Once Off Payment"
                    icon-path="check-solid.svg"
                    class="selectable-card"
                    :selected="transactionType === 'STANDARD'"
                    @select="setTransactionType('STANDARD')"
                    data-id="link_content_create transaction"
                    data-text="step 3| once off payment button click"
                />
                <CardButton
                    text="Milestone Payments"
                    icon-path="list-solid.svg"
                    class="selectable-card"
                    :selected="transactionType === 'MILESTONE'"
                    @select="setTransactionType('MILESTONE')"
                    data-id="link_content_create transaction"
                    data-text="step 3| milestone payments button click"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CardButton from '@/modules/common/components/input/card-button'

export default {
    name: 'TransactionTypeSelect',
    components: { CardButton },
    computed: {
        transactionType: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.workflow
            },
            set(v) {
                return this.$store.commit('txCreate/setTransactionField', { key: 'workflow', value: v })
            },
        },
    },
    methods: {
        setTransactionType(type) {
            const allocations = this.$store.state.txCreate.allocations.allocations
            if (type === 'Single' && allocations.length > 1) {
                console.log('need to remove extra allocations')
                this.$store.commit('txCreate/removeExtraAllocations')
            }

            this.transactionType = type
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem 0.25rem;
}
</style>
