<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h4 class="uk-text-secondary">Describe your transaction</h4>
            <p>Please describe what you are {{ userActionText }} below.</p>
        </div>

        <MainDetailsForm />

        <PartiesFees />

        <TransactionTypeSelect />

        <SinglePaymentCard v-if="transactionType === 'STANDARD'" />
        <MilestoneCreateList v-if="transactionType === 'MILESTONE'" />

        <TransactionInformation />
        <CreateTxNavButtons
            :disable-next="!allowProgress"
            anaylticsDataId="link_content_create transaction"
            anayliticsNextDataText="step 3| next button click"
            anayliticsBackDataText="step 3| back button click"
            anayliticsCancelDataText="step 3| cancel button click"
            anayliticsSetFormComplete
        />
    </div>
</template>

<script>
import PartiesFees from '../../common/parties-fees/index'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import MainDetailsForm from './main-details-form'
import TransactionTypeSelect from './transaction-type-select'
import SinglePaymentCard from './single-payment-card'
import MilestoneCreateList from './milestones-list'

export default {
    name: 'SBSADetails',
    components: {
        MilestoneCreateList,
        SinglePaymentCard,
        TransactionTypeSelect,
        MainDetailsForm,
        TransactionInformation,
        PartiesFees,
        CreateTxNavButtons,
    },
    data() {
        return {
            validation,
        }
    },
    mounted() {
        this.$setVirtualPageView(
            'home',
            'home:escrow create transaction:step 3 details',
            'home:escrow create transaction',
            'home:escrow create transaction:step 3 details',
        )
    },
    computed: {
        transactionType() {
            return this.$store.state.txCreate.transaction.transaction.workflow
        },
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
        allowProgress() {
            if (this.transactionType === 'MILESTONE' && this.allocations.length < 2) {
                return false
            }
            for (const alloc of this.$store.state.txCreate.allocations.allocations) {
                if (validation.allocationValue(alloc.value).length !== 0) {
                    return false
                }
            }
            return !!(validation.allocationDetailsComplete() && validation.basicDetailsComplete())
        },
    },
}
</script>
