<template>
    <div>
        <div class="commentbox" ref="commentbox">
            <div class="commentscontainer">
                <div class="commentsbackground"></div>
                <div v-if="comments.length === 0">
                    <div class="uk-flex uk-flex-center uk-margin-top">
                        <div class="uk-card uk-card-secondary uk-padding-small">No comments have been posted</div>
                    </div>
                </div>
                <div v-for="comment in comments" :key="comment.id">
                    <CommentCard :comment="comment" />
                </div>
            </div>
        </div>
        <GL-ErrorAlert :errorMessage="errorMessagePost" />
        <div v-if="!disableNewComments">
            <div class="uk-width-expand">
                <GL-TextAreaInput v-model="body" />
            </div>
            <div class="uk-flex uk-flex-right">
                <div class="uk-width-1-1 uk-width-auto@s">
                    <GL-Button
                        class="uk-width-expand uk-width-auto@s"
                        type="primary"
                        text="Post Comment"
                        loadingText="Posting"
                        disabledText="Post Comment"
                        :loading="loadingPost"
                        @click="postComment"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CommentCard from './overview-commentary-card'
import mixinTransaction from '@/modules/common/mixin-transaction'
export default {
    mixins: [mixinTransaction],
    components: {
        CommentCard,
    },
    data() {
        return {
            body: '',
            loadingPost: false,
            errorMessagePost: '',
        }
    },
    computed: {
        comments() {
            return this.transactionPartyFilled.comments
        },
        disableNewComments() {
            if (this.$store.state.transaction.transaction.metadata.activeParty.role === 'READ_ONLY') {
                return true
            }
            return (
                this.$store.state.transaction.transaction.state === 'FUNDS_RELEASED' ||
                this.$store.state.transaction.transaction.state === 'CANCELED' ||
                this.$store.state.transaction.transaction.state === 'DECLINED' ||
                this.$store.state.transaction.transaction.state === 'SUSPENDED' ||
                this.$store.state.transaction.transaction.state === 'ADMIN_SUSPENDED' ||
                this.$store.state.transaction.transaction.state === 'ADMIN_CANCELLED'
            )
        },
    },
    methods: {
        async postComment() {
            try {
                this.loadingPost = true
                this.errorMessagePost = ''
                await this.$store.dispatch('transaction/transactionCommentCreate', {
                    body: this.body,
                })
                this.body = ''
                await this.$store.dispatch('transaction/transactionRefetch')
                this.$getSeenService().markTransactionSeen(this.transaction)
                this.$globalEventEmit('notification', {
                    type: 'success',
                    title: 'Success!',
                    body: `You have left a comment. We have notified everyone`,
                })
            } catch (error) {
                this.errorMessagePost = this.$handleError(error)
            } finally {
                this.loadingPost = false
            }
        },
    },
    mounted() {
        var element = this.$refs.commentbox
        element.scrollTop = element.scrollHeight
        this.$getSeenService().markTransactionSeen(this.transaction)
    },
}
</script>

<style scoped>
.commentbox {
    min-height: 20rem;
    max-height: 30rem;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}
.commentscontainer {
    position: relative;
    min-height: 20rem;
    overflow: hidden;

    padding: 0.5rem;
}
.commentsbackground {
    background-color: rgba(200, 200, 200, 1);
    left: 0rem;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    opacity: 0.2;
    background-repeat: repeat;
    position: absolute;
}
</style>
