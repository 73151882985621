import SetUp from '../modules/transaction/components/transaction-create/setup/index'
import Parties from '../modules/transaction/components/transaction-create/parties/parties'
import Details from '../modules/transaction/components/transaction-create/details/index'
// import Loads from '../modules/transaction/components/transaction-create/loads/index'
import Documents from '../modules/transaction/components/transaction-create/documents'
import Overview from '../modules/transaction/components/transaction-create/overview'
import Delivery from '../modules/transaction/components/transaction-create/delivery/index'
// import Milestones from '../modules/transaction/components/transaction-create/milestones/index'

// const fuelAndMiningMilestoneToDifferentAddresses = [
//     {
//         label: 'Begin',
//         name: 'Create',
//         component: SetUp,
//     },
//     {
//         label: 'Add Parties',
//         name: 'Parties',
//         component: Parties,
//     },
//     {
//         label: 'Add Details',
//         name: 'Details',
//         component: Details,
//     },
//     {
//         label: 'Add Loads',
//         name: 'Loads',
//         component: Loads,
//     },
//     {
//         label: 'Add Documents',
//         name: 'Documents',
//         component: Documents,
//     },
//     {
//         label: 'Review & Submit',
//         name: 'Overview',
//         component: Overview,
//     },
// ]
//
// const fuelAndMiningMilestoneToSameAddress = [
//     {
//         label: 'Begin',
//         name: 'Create',
//         component: SetUp,
//     },
//     {
//         label: 'Add Parties',
//         name: 'Parties',
//         component: Parties,
//     },
//     {
//         label: 'Add Details',
//         name: 'Details',
//         component: Details,
//     },
//     {
//         label: 'Add Loads',
//         name: 'Loads',
//         component: Loads,
//     },
//     {
//         label: 'Delivery Details',
//         name: 'Delivery',
//         component: Delivery,
//     },
//     {
//         label: 'Add Documents',
//         name: 'Documents',
//         component: Documents,
//     },
//     {
//         label: 'Review & Submit',
//         name: 'Overview',
//         component: Overview,
//     },
// ]
//
// const propertyMilestones = [
//     {
//         label: 'Begin',
//         name: 'Create',
//         component: SetUp,
//     },
//     {
//         label: 'Add Parties',
//         name: 'Parties',
//         component: Parties,
//     },
//     {
//         label: 'Add Details',
//         name: 'Details',
//         component: Details,
//     },
//     {
//         label: 'Milestones',
//         name: 'Milestones',
//         component: Milestones,
//     },
//     {
//         label: 'Add Documents',
//         name: 'Documents',
//         component: Documents,
//     },
//     {
//         label: 'Review & Submit',
//         name: 'Overview',
//         component: Overview,
//     },
// ]
// const property = [
//     {
//         label: 'Begin',
//         name: 'Create',
//         component: SetUp,
//     },
//     {
//         label: 'Add Parties',
//         name: 'Parties',
//         component: Parties,
//     },
//     {
//         label: 'Add Details',
//         name: 'Details',
//         component: Details,
//     },
//     {
//         label: 'Add Documents',
//         name: 'Documents',
//         component: Documents,
//     },
//     {
//         label: 'Review & Submit',
//         name: 'Overview',
//         component: Overview,
//     },
// ]
//
// const generalMilestones = [
//     {
//         label: 'Begin',
//         name: 'Create',
//         component: SetUp,
//     },
//     {
//         label: 'Add Parties',
//         name: 'Parties',
//         component: Parties,
//     },
//     {
//         label: 'Add Details',
//         name: 'Details',
//         component: Details,
//     },
//     {
//         label: 'Milestones',
//         name: 'Milestones',
//         component: Milestones,
//     },
//     {
//         label: 'Delivery Details',
//         name: 'Delivery',
//         component: Delivery,
//     },
//     {
//         label: 'Add Documents',
//         name: 'Documents',
//         component: Documents,
//     },
//     {
//         label: 'Review & Submit',
//         name: 'Overview',
//         component: Overview,
//     },
// ]

const general = [
    {
        label: 'Begin',
        name: 'Create',
        component: SetUp,
    },
    {
        label: 'Add Parties',
        name: 'Parties',
        component: Parties,
        tourStep: 'create-tour-step-5',
    },
    {
        label: 'Add Details',
        name: 'Details',
        component: Details,
        tourStep: 'create-tour-step-6',
    },
    {
        label: 'Delivery Details',
        name: 'Delivery',
        component: Delivery,
        tourStep: 'create-tour-step-7',
    },
    {
        label: 'Add Documents',
        name: 'Documents',
        component: Documents,
        tourStep: 'create-tour-step-8',
    },
    {
        label: 'Review & Submit',
        name: 'Overview',
        component: Overview,
        tourStep: 'create-tour-step-9',
    },
]

// export const createSteps = (industry, txType, milestonesToSameAddress) => {
//     if (txType === 'Milestone' && (industry === 'FUEL' || industry === 'MINING')) {
//         if (milestonesToSameAddress) {
//             return fuelAndMiningMilestoneToSameAddress
//         }
//         return fuelAndMiningMilestoneToDifferentAddresses
//     }
//     if (industry === 'PROPERTY') {
//         if (txType === 'Milestone') {
//             return propertyMilestones
//         }
//         return property
//     }
//     if (txType === 'Milestone') {
//         return generalMilestones
//     }
//     return general
// }

export const createSteps = () => {
    return general
}
