export default {
    graphURL: 'https://escrow.standardbank.co.za/api/graphql',
    sessionTimeout: 600000,
    contactUsFormURL: 'https://nqx6b0dyjj.execute-api.af-south-1.amazonaws.com/prod',
    authServer: 'https://enterprisests.standardbank.co.za',
    authServerLogout: 'https://enterprisests.standardbank.co.za/idp/startSLO.ping',
    oauthClientID: '976916e4-c5cf-426e-b2b7-fd23ff1613b3',
    appDomain: 'https://escrow.standardbank.co.za',
    ecentricEndpoint: '',
    pingProfilePage: 'https://enterprisests.standardbank.co.za/pf/idprofile.ping?LocalIdentityProfileID=mEWKDcOFa46HVuug&userType=general',
}
