<template>
    <div class="uk-grid-small" uk-grid>
        <div>
            <label class="switch">
                <input type="checkbox" v-model="checkedComputed" :disabled="disabled" />
                <span class="slider round" :class="{ disabled: disabled }"></span>
            </label>
        </div>
        <div class="uk-width-expand">
            <label>
                {{ text }}
            </label>
            <GL-Icon v-if="tooltip" class="tool" path="question-circle-o.svg" :size="1" :uk-tooltip="tooltip" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'SwitchInput',
    props: {
        text: {
            type: String,
            default: '',
        },
        value: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        checkedComputed: {
            get() {
                return this.value
            },
            set(value) {
                if (!this.disabled) {
                    this.$emit('input', value)
                }
            },
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/switch.scss';
.tool {
    margin-left: 0.2rem;
    margin-bottom: 0.2rem;
    display: inline-block;
}
</style>
