<template>
    <InputBase :label="label" :tooltip="tooltip" :error-messages="errorMessages" :dirty="dirty">
        <textarea
            :name="name"
            :value="value"
            :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty }"
            class="uk-textarea"
            rows="5"
            @blur="$emit('blur', $event)"
            @input="input($event)"
        />
    </InputBase>
</template>

<script>
import InputBase from './input-base'
export default {
    name: 'TextAreaInput',
    components: { InputBase },
    data() {
        return {
            dirty: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    methods: {
        input(event) {
            this.dirty = true
            this.$emit('input', event.target.value)
        },
    },
}
</script>
