<template>
    <div>
        <MilestoneCard
            v-for="(allocation, index) in allocations"
            :key="index"
            :allocation="allocation"
            :index="index"
        />
        <div class="uk-text-center uk-margin-large-top">
            <GL-Button
                text="+ Add Milestone"
                type="primary-outlined"
                @click="$store.commit('txCreate/addAllocation')"
            />
        </div>
    </div>
</template>

<script>
import MilestoneCard from './milestone-card'

export default {
    name: 'MilestoneCreateList',
    components: { MilestoneCard },
    computed: {
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
    },
}
</script>
