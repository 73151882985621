<template>
    <div class="uk-margin-top uk-margin-bottom uk-width-1-1">
        <div class="uk-flex uk-flex-center">
            <div
                v-if="milestoneSummaryVisible && calculationDataReady"
                class="uk-card uk-card-default uk-card-body uk-animation-slide-right-small uk-width-5-6@m"
                :class="{ 'uk-card-small': $screenSmaller('small') }"
            >
                <div v-if="error" class="uk-flex uk-flex-center">
                    <GL-Alert class="uk-width-5-6@s" type="danger">
                        {{ error }}
                    </GL-Alert>
                </div>
                <div v-if="calculation === '__loading__' && !error">
                    <div class="uk-text-center">
                        <GL-Spinner :size="2" />
                    </div>
                </div>
                <div v-else-if="calculation !== '__loading__'">
                    <OverviewMilestones :transaction="transactionWithCalc" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OverviewMilestones from '../../../../common/components/overview/overview-milestones/overview-milestones'
export default {
    name: 'TransactionCalculation',
    props: {
        milestoneSummaryVisible: {
            type: Boolean,
            required: true,
        },
    },
    components: { OverviewMilestones },
    computed: {
        transactionWithCalc() {
            const transaction = this.$store.state.txCreate.transaction.transaction
            const transactionAuxData = JSON.stringify(this.$store.state.txCreate.transaction.auxiliaryData)
            const parties = this.$store.state.txCreate.parties.parties
            const allocations = this.$store.state.txCreate.allocations.allocations
            return {
                ...transaction,
                calculation: this.calculation,
                auxiliaryData: transactionAuxData,
                parties: [...parties],
                allocations: [...allocations],
            }
        },
        calculationDataReady() {
            return this.$store.getters['txCreate/calculationDataReady']
        },
        calculation() {
            return this.$store.state.txCreate.calculator.calculation
        },
        error() {
            return this.$store.state.txCreate.calculator.calculationError
        },
        dataChanged() {
            return this.$store.getters['txCreate/calculationDataChanged']
        },
        calculationData() {
            return this.$store.getters['txCreate/calculationData']
        },
        milestones() {
            return this.calculation.allocations.length > 1
        },
    },
    watch: {
        milestoneSummaryVisible() {
            if (this.milestoneSummaryVisible) {
                this.getCalculation()
            }
        },
        dataChanged() {
            if (this.dataChanged && this.milestoneSummaryVisible) {
                this.$store.commit('txCreate/setCalculation', '__loading__')
                setTimeout(this.getCalculation, 1500)
            }
        },
    },
    methods: {
        async getCalculation() {
            await this.$store.dispatch('txCreate/transactionCalculation')
        },
    },
}
</script>
