<template>
    <div>
        <SBSADetails />
    </div>
</template>

<script>
import { transactionCreate } from '../../../../../constants/constants-transaction-create'
import SBSADetails from './sbsa-details/sbsa-details'

export default {
    name: 'TransactionDetails',
    components: {
        SBSADetails,
    },
    data() {
        return {
            forms: {
                industry: 'General Goods',
            },
        }
    },
    computed: {
        form() {
            const selectedIndustry = this.$store.state.txCreate.transaction.transaction.industry
            const txType = this.$store.state.txCreate.transaction.transaction.workflow
            if (!selectedIndustry || !txType) {
                return null
            } else {
                const industry = transactionCreate.industries.find(industry => industry.value === selectedIndustry)
                return txType === 'MILESTONE' ? industry.formComponentName + 'Milestone' : industry.formComponentName
            }
        },
    },
}
</script>
