<template>
    <div>
        <div v-for="(allocation, index) in transaction.allocations" :key="index" class="uk-margin-bottom">
            <component
                :is="milestoneCardComponent"
                :index="index"
                :allocation="allocation"
                :transaction="transaction"
            />
        </div>

        <component :is="milestoneSummaryComponent" :transaction="transaction" />
    </div>
</template>
<script>
export default {
    props: {
        /*
            Transaction object is expected to have :
                - industry
                - auxliliaryData (raw text)
                - allocations
                - allocation.title
                - allocation.units
                - allocation.unitCost
                - allocation.daysToDeliver
                - allocation.daysToInspect
                - allocation.value
                - calculation
                - calculation.baseValue
        */
        transaction: {
            type: Object,
            required: true,
        },
    },
    computed: {
        milestoneCardComponent() {
            // const componentMap = {
            //     FUEL: 'fuel',
            //     MINING: 'mining',
            // }
            // const industry = componentMap[this.transaction.industry] || 'general'
            const industry = 'general'
            return () => import(`./overview-milestones-card-${industry}`)
        },
        milestoneSummaryComponent() {
            // const componentMap = {
            //     FUEL: 'fuel',
            //     MINING: 'mining',
            // }
            // const industry = componentMap[this.transaction.industry] || 'general'
            const industry = 'general'
            return () => import(`./overview-milestones-summary-${industry}`)
        },
    },
}
</script>
