import AuthAPI from '_shared/api/api-auth'

export default class SBSAAuth extends AuthAPI {
    constructor(api) {
        switch (window.location.pathname) {
            case '/login':
            case '/oauth/callback':
                break
            default:
            // api.auth.status().then(r => {
            //     if (r !== true) {
            //         // api.auth.loginRequest()
            //     }
            // })
        }

        super(api)
    }
}
