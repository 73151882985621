<template>
    <InputBase :label="label" :tooltip="tooltip" :error-messages="errorMessages" :dirty="dirty">
        <select
            :name="name"
            class="uk-select"
            :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty }"
            :value="value"
            :disabled="disabled"
            @blur="$emit('blur', $event)"
            @change="input($event)"
        >
            <option v-for="option in optionListComputed" :key="option.key" :value="option.key">
                {{ option.value }}
            </option>
        </select>
    </InputBase>
</template>

<script>
import InputBase from './input-base'
export default {
    name: 'GL-SelectInput',
    components: {
        InputBase,
    },
    data() {
        return {
            dirty: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        optionList: {
            type: Array,
            default() {
                return [
                    {
                        key: 'KEY',
                        value: 'Some option',
                    },
                ]
            },
        },
        tooltip: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    methods: {
        input(event) {
            this.dirty = true
            this.$emit('input', event.target.value)
        },
    },
    computed: {
        optionListComputed() {
            const optionListComputed = []
            for (const option of this.optionList) {
                if (!option.hidden) {
                    optionListComputed.push(option)
                }
            }
            return optionListComputed
        },
    },
}
</script>
