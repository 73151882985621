<template>
    <div>
        <div class="uk-flex uk-flex-between uk-flex-wrap uk-margin">
            <div><h4>Where must the delivery take place?</h4></div>
            <div>
                <GL-Button
                    v-if="showEdit"
                    text="Edit"
                    type="primary-outlined"
                    @click="$router.push(`/transaction/${transaction.id}/delivery`)"
                />
            </div>
        </div>
        <component :is="deliveryComponent" />
    </div>
</template>
<script>
import mixinTransaction from '@/modules/common/mixin-transaction'

export default {
    mixins: [mixinTransaction],

    props: {
        showEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        deliveryComponent() {
            // const componentMap = {
            //     FUEL: 'fuel',
            //     MINING: 'mining',
            // } componentMap[this.transaction.industry]
            const industry = 'general'
            return () => import(`./overview-delivery-${industry}`)
        },
    },
}
</script>
