import OrganizationValidate from './graphql/organization-validate.gql'
import OrganizationCompliance from './graphql/organization-compliance.gql'
import TransactionCompliance from './graphql/transaction-compliance.gql'
import DirectorSendNotification from './graphql/director-send-notification.gql'
import DirectorRequestOTP from './graphql/director-request-otp.gql'
import DirectorLookup from './graphql/director-lookup.gql'
import DirectorVerify from './graphql/director-verify.gql'
import DirectorDecline from './graphql/director-decline.gql'
import TransactionDirectorCheckInitiate from './graphql/transaction-director-check-initiate.gql'
import DirectorCheckReset from './graphql/director-check-reset.gql'
import { retryOrganizationValidate } from '../retry-api-call'

import config from '../../../config'

export default class APISBSA {
    constructor(api) {
        this.api = api
    }
    async postContactForm(message) {
        try {
            const fetchResponse = await fetch(config.contactUsFormURL, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(message),
            })
            return fetchResponse.text
        } catch (error) {
            return error
        }
    }

    async directorSendNotification(directorId) {
        const response = await this.api.client.mutate({
            mutation: DirectorSendNotification,
            variables: {
                id: directorId,
            },
        })
        return response.data.directorSendNotification
    }

    async directorCheckReset(directorId) {
        const response = await this.api.client.mutate({
            mutation: DirectorCheckReset,
            variables: {
                directorCheckId: directorId,
            },
        })
        return response.data.directorCheckReset
    }

    async organizationValidate(input) {
        const response = await retryOrganizationValidate(() =>
            this.api.client.query({
                query: OrganizationValidate,
                variables: {
                    input: {
                        registrationNumber: input.registrationNumber,
                        accountNumber: input.accountNumber,
                        bank: input.bank,
                    },
                },
            }),
        )
        return response.data.organizationValidate
    }
    async directorsOrganizationQuery(organizationId) {
        const response = await this.api.client.query({
            query: OrganizationCompliance,
            variables: {
                id: organizationId,
            },
        })

        if (response.data.organizationCompliance) {
            response.data.organizationCompliance.confirmationFor = 'ORGANIZATION'
            response.data.organizationCompliance.status = 'APPROVED'
            for (const director of response.data.organizationCompliance.directors) {
                if (director.verified === false) {
                    response.data.organizationCompliance.status = 'PENDING'
                }
            }
        }

        return response.data.organizationCompliance
    }

    async directorsTransactionQuery(partyId) {
        const response = await this.api.client.query({
            query: TransactionCompliance,
            variables: {
                partyId: partyId,
            },
        })

        if (response.data.transactionCompliance) {
            response.data.transactionCompliance.confirmationFor = 'TRANSACTION'
            response.data.transactionCompliance.status = 'APPROVED'
            for (const director of response.data.transactionCompliance.directors) {
                if (director.verified === false) {
                    response.data.transactionCompliance.status = 'PENDING'
                }
            }
        }

        return response.data.transactionCompliance
    }

    async directorsApprovalQuery(code) {
        const response = await this.api.client.query({
            query: DirectorLookup,
            variables: {
                id: code,
            },
        })

        return response.data.directorLookup
    }

    async directorsApprovalRequestOTP(code) {
        const response = await this.api.client.mutate({
            mutation: DirectorRequestOTP,
            variables: {
                id: code,
            },
        })
        return response.data.directorRequestOtp
    }

    async directorsApprovalInitiate(id, otp) {
        const response = await this.api.client.mutate({
            mutation: DirectorVerify,
            variables: {
                id,
                otp,
            },
        })
        return response.data.directorVerify
    }

    async directorsDeclineInitiate(id) {
        const response = await this.api.client.mutate({
            mutation: DirectorDecline,
            variables: {
                id,
            },
        })
        return response.data.directorDecline
    }

    async transactionDirectorCheckInitiate(transactionId) {
        const response = await this.api.client.mutate({
            mutation: TransactionDirectorCheckInitiate,
            variables: {
                transactionId,
            },
        })
        return response.data.transactionDirectorCheckInitiate
    }
}
