<template>
    <div>
        <div class="uk-container">
            <h4 class="subheading uk-margin-medium-top">
                EASILY MANAGE AND VIEW ALL YOUR CURRENT AND PAST TRANSACTIONS BELOW
            </h4>
        </div>
        <div id="transactions" class="uk-container home-tour-step-7">
            <div class="uk-margin-top uk-margin-large-bottom" :class="{ 'uk-grid': $screenLarger('small') }">
                <div>
                    <div
                        class="tab-selectable"
                        :class="{ 'tab-selected': selectedTab === 'ACTIVE' }"
                        @click="selectTab('ACTIVE')"
                        data-id="link_tab_tab"
                        data-text="transactions | current transactions link click"
                    >
                        Current Transactions
                    </div>
                </div>
                <div>
                    <div
                        class="tab-selectable"
                        :class="{ 'tab-selected': selectedTab === 'COMPLETE' }"
                        @click="selectTab('COMPLETE')"
                        data-id="link_tab_tab"
                        data-text="transactions | completed transactions link click"
                    >
                        Completed Transactions
                    </div>
                </div>
                <div>
                    <div
                        class="tab-selectable"
                        :class="{ 'tab-selected': selectedTab === 'CANCELED' }"
                        @click="selectTab('CANCELED')"
                        data-id="link_tab_tab"
                        data-text="transactions | cancelled transactions link click"
                    >
                        Cancelled Transactions
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-container">
            <!-- If Context Loading -->
            <div
                v-if="context === '__loading__'"
                style="color: black; margin-top: 10rem; margin-bottom: 10rem"
                class="uk-text-center"
            >
                <GL-Spinner :size="5" />
            </div>
            <!-- If Context Finished -->
            <div v-if="context !== '__loading__'">
                <!-- If Context Email Unverified -->
                <div v-if="context.status.emailVerified === false">
                    <!-- If Context Has Active Transactions -->
                    <div class="uk-text-center uk-margin-large" v-if="user.status.hasActiveTransactions === true">
                        <GL-Alert type="warning">
                            <GL-Icon path="exclamation-circle-solid.svg" :size="2" /> <br />
                            <br />
                            You have active transactions waiting for you. <br />
                            To view and access your transactions, please verify your email.
                        </GL-Alert>
                    </div>
                    <div class="uk-text-center uk-margin-large" v-if="user.status.hasActiveTransactions === false">
                        Your transactions will show here.
                    </div>
                </div>
                <!-- If Context Email Verified -->
                <div v-if="context.status.emailVerified === true">
                    <!-- If TransactionList Loading -->
                    <div
                        v-if="transactionList === '__loading__'"
                        style="color: black; margin-top: 10rem; margin-bottom: 10rem"
                        class="uk-text-center"
                    >
                        <GL-Spinner :size="5" />
                    </div>
                    <!-- If TransactionList Finished -->
                    <div v-if="transactionList !== '__loading__'">
                        <!-- If No Transactions -->
                        <div class="uk-text-center uk-margin-large" v-if="transactionList.data.length === 0">
                            You have no active transactions
                        </div>
                        <!-- If Transactions -->
                        <div class="my-trades-cards-panel" v-if="transactionList.data.length > 0" uk-grid>
                            <div
                                v-for="transaction in transactionList.data"
                                :key="transaction.id"
                                class="uk-width-1-2@m"
                            >
                                <TransactionCard
                                    :transaction="transaction"
                                    :disabled="!navigateTransactionEnabled"
                                    :disabledTooltip="navigateTransactionTooltip"
                                />
                            </div>
                        </div>
                        <div class="uk-flex uk-flex-center uk-margin-top">
                            <div class="uk-display-block">
                                <Pagination
                                    :paginatorInfo="transactionList.paginatorInfo"
                                    @set-page="navigateTransactionListPage"
                                />
                            </div>
                        </div>
                        <div class="uk-margin-large"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TransactionCard from '../components/transaction-card'
import Pagination from '@/modules/common/components/pagination'
import Vuex from "vuex";
export default {
    components: {
        TransactionCard,
        Pagination,
    },
    computed: {
        ...Vuex.mapState({
            user: state => state.user.user,
            context: state => state.context.context,
            transactionList: state => state.transaction.transactionList,
        }),
        selectedTab() {
            return this.$store.state.transaction.transactionListType
        },
        navigateTransactionEnabled() {
            if (this.$store.getters['context/contextVerification']) {
                return false
            }
            return true
        },
        navigateTransactionTooltip() {
            if (this.$store.getters['context/contextVerification']) {
                return this.$store.getters['context/contextVerification'].message
            }
            return null
        },
    },
    mounted() {
        this.selectTab('ACTIVE')
    },
    methods: {
        navigateTransactionListPage(page) {
            this.$store.commit('transaction/setTransactionListPage', page)
            this.$store.commit('transaction/setTransactionList', '__loading__')
            this.$store.dispatch('transaction/transactionListFetch')
        },
        selectTab(tab) {
            this.$store.commit('transaction/setTransactionListType', tab)
            this.$store.commit('transaction/setTransactionListPage', 1)
            this.$store.commit('transaction/setTransactionList', '__loading__')
            this.$store.dispatch('transaction/transactionListFetch')
            // if (this.user.status.verified) {
            //     this.$store.commit('transaction/setTransactionList', '__loading__')
            //     this.$store.dispatch('transaction/transactionListFetch')
            // }
        },
    },
}
</script>

<style lang="scss" scoped>
@media (max-width: 640px) {
    .uk-container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
</style>
