<template>
    <div class="uk-margin-large-bottom">
        <div v-if="context === '__loading__'" class="uk-card uk-card-default uk-card-body">
            <div class="uk-text-center" style="margin-top: 8rem; margin-bottom: 8rem">
                <GL-Spinner :size="2" />
            </div>
        </div>
        <div
            v-if="context !== '__loading__'"
            class="uk-card uk-card-default escrow-cta-panel uk-flex uk-animation-slide-top-small"
        >
            <div class="left-panel uk-visible@m">
                <div
                    class="image-panel"
                    :class="{
                        'image-panel-personal': !$store.state.context.actingAsBusiness,
                        'image-panel-business': $store.state.context.actingAsBusiness,
                    }"
                ></div>
            </div>
            <div class="right-panel home-tour-step-6">
                <h3 class="uk-margin-small-bottom">Welcome {{ context.name }}</h3>
                <h2 class="uk-margin-remove-top">
                    Escrow receives, holds and only pays the funds when both buyer and seller are satisfied.
                </h2>
                <div id="escrowWalletDetails" class="uk-grid uk-child-width-1-2@s">
                    <div>
                        <div class="beneficiary-amount">
                            <span class="beneficiary-currency">R</span>{{ context.funds.balance | currency }}
                        </div>
                        <span class="small-detail-text uk-text-uppercase">Your Escrow account balance</span>
                        <div class="beneficiary-amount">
                            <span class="beneficiary-currency">R</span>{{ context.funds.active | currency }}
                        </div>
                        <span class="small-detail-text uk-text-uppercase">
                            Total funds allocated to your transactions
                        </span>
                    </div>
                    <div>
                        <div class="beneficiary-amount">
                            <span class="beneficiary-currency">R</span>{{ context.funds.owed | currency }}
                        </div>
                        <span class="small-detail-text uk-text-uppercase">Funds in escrow payable to you</span>
                    </div>
                </div>
                <div class="uk-text-center uk-grid-small uk-margin-top" uk-grid>
                    <div class="uk-width-1-1 uk-width-1-2@s">
                        <GL-Button
                            data-id="link_content_product card"
                            data-text="welcome | create new transaction button click"
                            name="create-transaction-button"
                            type="primary"
                            text="Create new transaction"
                            class="uk-width-1-1 uk-margin-remove-bottom home-tour-step-4"
                            :uk-tooltip="createTransactionTooltip"
                            @click="createTransaction"
                            :disabled="!createTransactionEnabled"
                        />
                    </div>
                    <div class="uk-width-1-1 uk-width-1-2@s">
                        <GL-Button
                            data-id="link_content_product card"
                            data-text="welcome | fund my escrow account button click"
                            text="View my Escrow account"
                            class="uk-width-1-1 uk-margin-remove-bottom home-tour-step-5"
                            @click="navigate('/escrow-account')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <ModalCreate ref="ModalCreate" />
    </div>
</template>
<script>
import ModalCreate from './modal-create'
import Vuex from "vuex";
export default {
    components: { ModalCreate },
    computed: {
        ...Vuex.mapState({
            context: state => state.context.context,
        }),
        createTransactionEnabled() {
            if (this.$store.getters['context/contextVerification']) {
                return false
            }
            return true
        },
        createTransactionTooltip() {
            if (this.$store.getters['context/contextVerification']) {
                return this.$store.getters['context/contextVerification'].message
            }
            return null
        },
    },

    methods: {
        async createTransaction() {
            if (this.$store.getters['context/hasBusiness']) {
                const result = await this.$refs.ModalCreate.show()
                if (result.submit) {
                    this.$router.push('/transaction/create')
                }
            } else {
                this.$router.push('/transaction/create')
            }
        },
        navigate(target) {
            this.$router.push(target)
        },
    },
}
</script>
