<template>
    <div class="uk-margin">
        <h4 v-if="!hideHeading" class="uk-text-center uk-text-secondary">
            Delivery of the product or service will take place at the following address.
        </h4>
        <p v-if="!hideSubHeading" class="uk-text-center uk-text-bold">
            Please note that these address details will be disclosed to the other party.
        </p>
        <div class="uk-card uk-card-default uk-padding-small uk-width-1-1">
            <div class="uk-margin-small-right uk-margin-small-left uk-margin-small-top uk-margin-small-bottom">
                <GL-SelectInput
                    class="uk-width-1-1"
                    label="Select the address"
                    v-model="selectedAddress"
                    :option-list="addressOptions"
                />
            </div>
        </div>

        <form
            class="uk-card uk-card-default uk-padding-small uk-margin-top uk-width-1-1 uk-animation-slide-right-small"
            v-if="selectedAddress"
            data-frmcontainer="Delivery Create Transaction"
            @submit.prevent
        >
            <div class="uk-margin-small-right uk-margin-small-left uk-margin-small-top uk-margin-small-bottom">
                <GL-TextInput
                    class="uk-width-1-1"
                    :label="addressNameLabel"
                    :tooltip="addressNameTooltip"
                    v-model="name"
                />
                <div>
                    <div uk-grid>
                        <GL-TextInput class="uk-width-1-1 uk-margin-small-top" label="Street / Complex" v-model="line1" />
                        <GL-TextInput class="uk-width-1-2@s uk-margin-small-top" label="Address line 2" v-model="line2" />
                        <GL-TextInput class="uk-width-1-2@s uk-margin-small-top" label="Suburb" v-model="line3" />
                        <GL-TextInput class="uk-width-1-2@s uk-margin-small-top" label="City" v-model="city" />
                        <GL-TextInput class="uk-width-1-2@s uk-margin-small-top" label="Postal Code" v-model="code" />
                        <GL-TextInput class="uk-width-1-2@s uk-margin-small-top" label="State / Province" v-model="area" />
                    </div>
                </div>
            </div>
        </form>

        <div
            class="uk-card uk-card-default uk-padding-small uk-margin-top uk-width-1-1 uk-animation-slide-right-small"
            v-if="selectedAddress"
        >
            <div class="uk-margin-small-right uk-margin-small-left uk-margin-small-top uk-margin-small-bottom">
                <GL-TextAreaInput class="uk-width-1-1" label="Delivery Instructions" v-model="deliveryInstructions" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddressForm',
    props: {
        hideHeading: {
            type: Boolean,
            default: false,
        },
        hideSubHeading: {
            type: Boolean,
            default: false,
        },
        addressNameLabel: {
            type: String,
            default: 'Give Your Address a Name',
        },
        addressNameTooltip: {
            type: String,
            default: 'i.e. Office, Home, Warehouse',
        },
    },
    computed: {
        addresses() {
            if (this.$store.state.user.fetchedUser === '__loading__') {
                return []
            }
            return this.$store.state.user.fetchedUser.addresses
        },
        addressOptions() {
            const options = this.addresses.map(({ name, id }) => ({ value: name, key: id }))
            options.push({ value: 'Create New Address', key: 'NEW' })
            return options
        },
        selectedAddress: {
            get() {
                return this.$store.state.txCreate.address.address.id
            },
            set(v) {
                if (v === 'NEW') {
                    this.$store.commit('txCreate/setAddressField', { key: 'id', value: v })
                    this.$store.commit('txCreate/setAddressField', { key: 'name', value: '' })
                    this.$store.commit('txCreate/setAddressField', { key: 'line1', value: '' })
                    this.$store.commit('txCreate/setAddressField', { key: 'line2', value: '' })
                    this.$store.commit('txCreate/setAddressField', { key: 'line3', value: '' })
                    this.$store.commit('txCreate/setAddressField', { key: 'city', value: '' })
                    this.$store.commit('txCreate/setAddressField', { key: 'area', value: '' })
                    this.$store.commit('txCreate/setAddressField', { key: 'code', value: '' })
                } else {
                    const address = this.userAddressById(v)
                    this.$store.commit('txCreate/setAddressField', { key: 'id', value: address.id })
                    this.$store.commit('txCreate/setAddressField', { key: 'name', value: address.name })
                    this.$store.commit('txCreate/setAddressField', { key: 'line1', value: address.line1 })
                    this.$store.commit('txCreate/setAddressField', { key: 'line2', value: address.line2 })
                    this.$store.commit('txCreate/setAddressField', { key: 'line3', value: address.line3 })
                    this.$store.commit('txCreate/setAddressField', { key: 'city', value: address.city })
                    this.$store.commit('txCreate/setAddressField', { key: 'area', value: address.area })
                    this.$store.commit('txCreate/setAddressField', { key: 'code', value: address.code })
                }
            },
        },
        name: {
            get() {
                return this.$store.state.txCreate.address.address.name
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'name', value: v })
            },
        },
        line1: {
            get() {
                return this.$store.state.txCreate.address.address.line1
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'line1', value: v })
            },
        },
        line2: {
            get() {
                return this.$store.state.txCreate.address.address.line2
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'line2', value: v })
            },
        },
        line3: {
            get() {
                return this.$store.state.txCreate.address.address.line3
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'line3', value: v })
            },
        },
        city: {
            get() {
                return this.$store.state.txCreate.address.address.city
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'city', value: v })
            },
        },
        area: {
            get() {
                return this.$store.state.txCreate.address.address.area
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'area', value: v })
            },
        },
        code: {
            get() {
                return this.$store.state.txCreate.address.address.code
            },
            set(v) {
                this.$store.commit('txCreate/setAddressField', { key: 'code', value: v })
            },
        },
        deliveryInstructions: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].deliveryInstructions
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: 0,
                    key: 'deliveryInstructions',
                    value: value,
                })
            },
        },
    },
    methods: {
        userAddressById(id) {
            return this.$store.state.user.fetchedUser.addresses.find(address => address.id === id)
        },
    },
}
</script>
