import { formatCurrency, formatNumber } from '@/scripts/filters'
// import validation from '@/scripts/validation/validation'

export default {
    getIndustry(industryBase) {
        let industry = industryBase
        industry.buyerName = 'Buyer'
        industry.sellerName = 'Seller'
        industry.allocationName = 'Milestone'
        industry.allocationsName = 'Milestones'

        industry.getMiningMassUnits = function (auxiliaryData) {
            if (auxiliaryData.miningCommodityType === 'PRECIOUS') {
                if (auxiliaryData.miningCommodity === 'DIAMONDS') {
                    return {
                        label: 'Carats',
                        price: 'Price Per Carat',
                    }
                }
                return {
                    label: 'Troy Ounces',
                    price: 'Price Per Troy Ounce',
                }
            }
            return {
                label: 'Tonnage',
                price: 'Price Per Ton',
            }
        }
        // industry.allocationValues = function (allocation, transaction) {
        //     if (!transaction) {
        //         console.error('Transaction is required to compute MINING values')
        //     }
        //     if (!transaction.auxiliaryData) {
        //         console.error('Transaction auxiliaryData is required to compute MINING values')
        //     }
        //     const unitDescription = industry.getMiningMassUnits(JSON.parse(transaction.auxiliaryData))
        //     const values = []
        //     values.push({
        //         name: 'units',
        //         label: unitDescription.label,
        //         value: formatNumber(allocation.units),
        //     })
        //     values.push({
        //         name: 'priceperunit',
        //         label: unitDescription.price,
        //         value: 'R' + formatCurrency(allocation.unitCost),
        //     })
        //     values.push({
        //         name: 'totalvalue',
        //         label: `Total`,
        //         value: 'R' + formatCurrency(allocation.value),
        //     })
        //     values.push({
        //         name: 'deliver',
        //         label: 'days to deliver',
        //         value: allocation.daysToDeliver,
        //     })
        //     values.push({
        //         name: 'inspect',
        //         label: 'days to inspect',
        //         value: allocation.daysToInspect,
        //     })

        //     return values
        // }

        // industry.allocationAmendableValues = function (allocation, transaction) {
        //     if (!transaction) {
        //         console.error('Transaction is required to compute MINING amendableValues')
        //     }
        //     if (!transaction.auxiliaryData) {
        //         console.error('Transaction auxiliaryData is required to compute MINING amendableValues')
        //     }
        //     const unitDescription = industry.getMiningMassUnits(JSON.parse(transaction.auxiliaryData))
        //     const amendableValues = []
        //     amendableValues.push({
        //         modeltarget: 'units',
        //         value: allocation.units,
        //         component: 'GL-NumberInput',
        //         componentprops: {
        //             label: unitDescription.label,
        //             showSpin: true,
        //             class: 'uk-width-1-2',
        //         },
        //         validations: [validation.list.required(`Please provide the ${unitDescription.label}`)],
        //     })
        //     amendableValues.push({
        //         modeltarget: 'unitcost',
        //         value: allocation.unitCost,
        //         component: 'GL-CurrencyInput',
        //         componentprops: {
        //             label: unitDescription.price,
        //             showSpin: true,
        //             class: 'uk-width-1-2',
        //         },
        //         validations: [validation.list.required(`Please provide the ${unitDescription.price}`)],
        //     })
        //     amendableValues.push({
        //         modeltarget: 'daysToDeliver',
        //         value: allocation.daysToDeliver,
        //         component: 'GL-NumberInput',
        //         componentprops: {
        //             label: 'DAYS FOR DELIVERY',
        //             showSpin: true,
        //             class: 'uk-width-1-2',
        //         },
        //         validations: [validation.list.required('Please provide the days to deliver')],
        //     })
        //     amendableValues.push({
        //         modeltarget: 'daysToInspect',
        //         value: allocation.daysToInspect,
        //         component: 'GL-NumberInput',
        //         componentprops: {
        //             label: 'DAYS FOR INSPECTION',
        //             showSpin: true,
        //             class: 'uk-width-1-2',
        //         },
        //         validations: [validation.list.required('Please provide the days to deliver')],
        //     })

        //     return amendableValues
        // }

        industry.allocationAmendmentDelta = function (allocation, amendment, transaction) {
            let changeList = []
            let auxiliaryData = JSON.parse(allocation.auxiliaryData)

            if (transaction.allocations.length === 1) {
                // This is to keep the change service generalized to allocations
                auxiliaryData = JSON.parse(transaction.auxiliaryData)
            }
            if (allocation.units !== amendment.units) {
                changeList.push({
                    field: industry.getMiningMassUnits(auxiliaryData).label.toLowerCase(),
                    from: formatNumber(allocation.units),
                    to: formatNumber(amendment.units),
                })
            }
            if (allocation.unitCost !== amendment.unitCost) {
                changeList.push({
                    field: industry.getMiningMassUnits(auxiliaryData).price.toLowerCase(),
                    from: 'R' + formatCurrency(allocation.unitCost),
                    to: 'R' + formatCurrency(amendment.unitCost),
                })
            }
        }

        return industry
    },
}
