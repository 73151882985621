<template>
    <div>
        <div v-if="currentUser === '__loading__'">
            <div class="uk-text-center">
                <GL-Spinner />
            </div>
        </div>
        <!-- Select Your Role-->
        <div
            id="roleSelector"
            class="uk-margin uk-text-center"
            :class="{ 'uk-invisible': currentUser === '__loading__' }"
        >
            <h4 class="uk-text-secondary">SELECT YOUR ROLE</h4>
            <p>Are you the BUYER or SELLER in this transaction?</p>

            <div class="uk-flex uk-flex-center uk-flex-wrap create-tour-step-2">
                <CardButton
                    :description="buyer.description"
                    :text="buyer.description"
                    :value="buyer.key"
                    :icon-path="buyer.icon"
                    class="selectable-card"
                    :disabled="disableRoleSelect(buyer.key)"
                    :selected="buyer.key === selectedRole"
                    @select="selectRole(buyer.key)"
                    :uk-tooltip="disableRoleSelect(buyer.key) ? 'Only Standard Bank customers may act as a buyer' : ''"
                    data-id="link_content_create transaction"
                    data-text="step 1 | buying product button click"
                />
                <CardButton
                    :description="seller.description"
                    :text="seller.description"
                    :value="seller.key"
                    :icon-path="seller.icon"
                    class="selectable-card"
                    :disabled="disableRoleSelect(seller.key)"
                    :selected="seller.key === selectedRole"
                    @select="selectRole(seller.key)"
                    :uk-tooltip="disableRoleSelect(seller.key) ? 'Only Standard Bank customers may act as a buyer' : ''"
                    data-id="link_content_create transaction"
                    data-text="step 1 | selling product button click"
                />
                <!-- <CardButton
                    v-for="role in availableRoles"
                    :key="role.key"
                    :description="role.description"
                    :text="role.description"
                    :value="role.key"
                    :icon-path="role.icon"
                    class="selectable-card"
                    :disabled="disableRoleSelect(role.key)"
                    :selected="role.key === selectedRole"
                    @select="selectRole(role.key)"
                    :uk-tooltip="disableRoleSelect(role.key) ? 'Only Standard Bank customers may act as a buyer' : ''"
                /> -->
            </div>
        </div>

        <!-- If Agent Select Confidentiality -->
        <!-- <div
            id="confidentialitySelector"
            v-show="selectedRole === 'AGENT'"
            class="uk-padding uk-animation-slide-bottom-small uk-flex uk-flex-center uk-card uk-card-default uk-margin"
        >
            <ConfidentialityToggles />
        </div> -->

        <!-- Select Your industry-->
        <!-- <div
            id="industrySelector"
            :class="{
                'uk-invisible': hideIndustrySelector,
                'uk-animation-slide-bottom-small': selectedRole && !selectedIndustry,
            }"
            class="uk-padding uk-card uk-card-default uk-margin create-tour-step-3"
        >
            <h4 class="uk-text-secondary uk-text-center">WHAT IS THE INDUSTRY?</h4>
            <div class="uk-flex uk-flex-center uk-flex-wrap">
                <CardButtonWide
                    v-for="industry in availableIndustries"
                    :key="industry.name"
                    :value="industry.name"
                    :text="industry.name"
                    :icon-path="industry.icon"
                    :description="industry.description"
                    class="selectable-card"
                    :disabled="disableIndustry(industry)"
                    :disabled-text="industry.disabledText"
                    :selected="industry.value === selectedIndustry"
                    @select="selectIndustry(industry)"
                ></CardButtonWide>
            </div>
        </div> -->

        <!-- Select Transaction Type-->
        <!-- <div
            id="transactionTypeSelector"
            :class="{
                'uk-invisible': hideTransactionType,
                'uk-animation-slide-bottom-small': selectedIndustry && !selectedTransactionType,
            }"
            class="uk-padding uk-card uk-card-default uk-margin create-tour-step-4"
        >
            <h4 class="uk-text-secondary uk-text-center">SELECT TYPE OF TRANSACTION</h4>
            <div class="uk-flex uk-flex-center uk-flex-wrap">
                <CardButtonWide
                    v-for="type in availableTransactionTypes"
                    :key="type.name"
                    :text="type.name"
                    :value="type.value"
                    :icon-path="type.icon"
                    :description="type.description"
                    class="selectable-card"
                    @select="selectTransactionType"
                    :selected="type.value === selectedTransactionType"
                    :disabled-text="type.disabledText"
                    :disabled="type.disabled"
                ></CardButtonWide>
            </div>
        </div> -->
        <CreateTxNavButtons
            :disable-next="!allowProgress"
            anaylticsDataId="link_content_create transaction"
            anayliticsNextDataText="step 1| next button click"
            anayliticsCancelDataText="step 1| cancel button click"
        />
        <Tour name="createTour" :steps="createTourSteps" :callbacks="tourCallbacks" />
    </div>
</template>

<script>
import CardButton from '@/modules/common/components/input/card-button'
import { transactionCreate } from '@/constants/constants-transaction-create'
// import ConfidentialityToggles from './confidentiality-toggles'
import CreateTxNavButtons from '../common/create-transaction-navigation'
import UIkit from 'uikit'
import Tour from '@/modules/common/components/tour'
import { steps as createTourSteps, callbacks as tourCallbacks } from '@/scripts/tours/transaction-create-tour'

export default {
    name: 'SetUp',
    components: { Tour, CreateTxNavButtons, CardButton },
    data() {
        return {
            availableIndustries: transactionCreate.industries,
            availableRoles: transactionCreate.roles,
            createTourSteps,
            tourCallbacks,
        }
    },

    mounted() {
        this.$setVirtualPageView(
            'home',
            'home:escrow create transaction:step 1 role',
            'home:escrow create transaction',
            'home:escrow create transaction:step 1 role',
        )
    },
    computed: {
        buyer() {
            return transactionCreate.roles.find(role => role.key === 'BUYER')
        },
        seller() {
            return transactionCreate.roles.find(role => role.key === 'SELLER')
        },
        hideIndustrySelector() {
            if (this.tourRunning) {
                return false
            } else if (this.selectedRole) {
                return false
            }
            return true
        },
        hideTransactionType() {
            if (this.tourRunning) {
                return false
            } else if (this.selectedIndustry) {
                return false
            }
            return true
        },
        tourRunning() {
            return this.$store.state.tour.tourRunning
        },
        availableTransactionTypes() {
            if (this.selectedIndustry === 'FUEL' || this.selectedIndustry === 'MINING') {
                return transactionCreate.transactionTypesFuelMining
            }
            return transactionCreate.transactionTypes
        },
        allowProgress() {
            return !!this.selectedRole
        },
        currentUser() {
            return this.$store.state.user.fetchedUser
        },
        selectedRole: {
            get() {
                return this.$store.state.txCreate.parties.parties[0].role
            },
            set(value) {
                if (this.tourRunning) {
                    return
                }
                this.$store.commit('txCreate/setCurrentUserRole', {
                    email: this.currentUser.email,
                    role: value,
                })
            },
        },
        selectedIndustry: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.industry
            },
            set(industry) {
                if (this.tourRunning) {
                    return
                }
                this.$store.commit('txCreate/setTransactionField', { key: 'industry', value: industry.value })
                if (industry.value === 'SOFTWARE_DEV_WEB_DOMAINS') {
                    this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'domains', value: [''] })
                } else {
                    this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'domains', value: '' })
                }
            },
        },
    },
    methods: {
        disableRoleSelect(role) {
            if (
                this.$store.state.context.context !== '__loading__' &&
                role === 'BUYER' &&
                this.$store.state.context.context.bankAccount.bank !== 'SBSA'
            ) {
                return true
            }
            return false
        },
        selectRole(role) {
            this.selectedRole = role
        },
        selectIndustry(industry) {
            this.selectedIndustry = industry
            this.$nextTick(() => UIkit.scroll('#transactionTypeSelector').scrollTo('#transactionTypeSelector'))
        },
        disableIndustry(industry) {
            if (industry.disabled) {
                return true
            }
            if (industry.value === 'FUEL') {
                const context = this.$store.state.context.context
                if (context.areaOfOperation && context.areaOfOperation.includes('FUEL')) {
                    return false
                } else {
                    return true
                }
            }
            return false
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem 0.25rem;
}
</style>
