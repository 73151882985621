<template>
    <div class="tx-create-nav">
        <CancelTransactionCreateModal ref="cancelModal" />
        <div v-if="error" class="uk-flex uk-flex-center">
            <GL-Alert class="uk-width-5-6@s" type="danger">
                {{ error }}
            </GL-Alert>
        </div>
        <div class="uk-margin-large-top">
            <p v-if="$store.getters['context/sbsaBusiness'] && lastStep" class="uk-margin-remove-bottom">
                Clicking the 'FINISH AND INVITE' button will initate a request for approval from your company's
                directors. Once we have approval from all the directors, an invitation will automatically be sent to the
                other party.
            </p>
            <hr class="uk-margin-remove-bottom" />
            <div class="uk-flex uk-flex-between uk-flex-wrap uk-margin-top">
                <GL-Button
                    text="cancel"
                    type="default"
                    class="create-transaction-control-button uk-width-1-1 uk-width-auto@s"
                    @click="showModal"
                    :class="{ 'uk-invisible': editMode }"
                    :data-id="anaylticsDataId"
                    :data-text="anayliticsCancelDataText"
                />
                <div class="uk-width-1-1 uk-width-auto@s">
                    <GL-Button
                        :text="leftButtonLabel"
                        :class="{ 'uk-invisible': hideBackButton }"
                        type="default"
                        class="create-transaction-control-button uk-width-1-1 uk-width-auto@s uk-margin-right"
                        @click="leftButtonActions"
                        :data-id="anaylticsDataId"
                        :data-text="anayliticsBackDataText"
                    />
                    <GL-Button
                        :text="rightButtonLabel"
                        type="primary"
                        :disabled="disableNext"
                        :disabled-text="rightButtonLabel"
                        class="create-transaction-control-button uk-width-1-1 uk-width-auto@s"
                        :loading="loading"
                        loading-text="Saving"
                        :tooltip="rightButtonTooltip"
                        @click="rightButtonActions"
                        :data-id="anaylticsDataId"
                        :data-text="anayliticsNextDataText"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CancelTransactionCreateModal from './cancel-transaction-create-modal'
export default {
    name: 'CreateTxNavButtons',
    props: {
        anaylticsDataId: {
            type: String,
        },
        anayliticsNextDataText: {
            type: String,
        },
        anayliticsBackDataText: {
            type: String,
        },
        anayliticsCancelDataText: {
            type: String,
        },
        anayliticsSetFormComplete: {
            type: Boolean,
            default: false,
        },
        disableNext: {
            type: Boolean,
            required: true,
        },
        lastStep: {
            type: Boolean,
            default: false,
        },
        rightButtonTooltip: {
            type: String,
            default: '',
        },
    },
    components: { CancelTransactionCreateModal },
    data() {
        return {
            error: '',
            loading: false,
        }
    },
    computed: {
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        hideBackButton() {
            return !!(this.editMode && this.lastStep)
        },
        leftButtonLabel() {
            if (this.editMode) {
                return 'Back To Overview'
            }
            return 'Back'
        },
        rightButtonLabel() {
            if (this.editMode) {
                if (this.lastStep) {
                    return 'Done'
                }
                return 'Save'
            }
            if (this.lastStep) {
                return 'Finish And Invite'
            }
            return 'Next'
        },
    },
    methods: {
        scrollTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        leftButtonActions() {
            if (this.editMode) {
                this.$store.dispatch('txCreate/setStepByName', 'Overview')
            } else {
                this.$store.commit('txCreate/decrementShowStep')
            }
            this.scrollTop()
        },
        async rightButtonActions() {
            if (this.anayliticsSetFormComplete) {
                this.$setFormComplete()
            }
            if (this.editMode && this.lastStep) {
                await this.$router.push(this.$route.path)
            } else {
                this.loading = true
                try {
                    this.error = ''

                    if (this.lastStep) {
                        // If acting as business
                        if (this.$store.getters['context/context'].__typename === 'Organization') {
                            // If SBSA
                            if (this.$store.getters['context/context'].bankAccount.bank === 'SBSA') {
                                // await this.$getApi().sbsa.directorsTransactionRequest(this.$route.params.id)
                                await this.$getApi().sbsa.transactionDirectorCheckInitiate(this.$route.params.id)
                                await this.$store.dispatch('transaction/transactionFetch', {
                                    id: this.$route.params.id,
                                })
                                this.$globalEventEmit('notification', {
                                    type: 'success',
                                    title: 'Success!',
                                    body: "A request for approval has been sent to your company's directors.",
                                })
                            }
                            // If Other
                            else {
                                await this.$store.dispatch('txCreate/saveAndAdvanceStep')
                            }
                        } else {
                            await this.$store.dispatch('txCreate/saveAndAdvanceStep')
                        }
                    } else {
                        await this.$store.dispatch('txCreate/saveAndAdvanceStep')
                    }

                    if (!this.lastStep) {
                        this.scrollTop()
                    }
                } catch (e) {
                    this.error = this.$handleError(e)
                }
                this.loading = false
            }
        },
        showModal() {
            if (this.$store.state.transaction.transaction !== '__loading__') {
                this.$refs.cancelModal.show()
            } else {
                this.$router.push('/')
            }
        },
    },
}
</script>

<style scoped>
.tx-create-nav {
    margin-top: 2rem;
}
</style>
