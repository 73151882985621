<template>
    <InputBase
        :label="label"
        :tooltip="tooltip"
        :error-messages="errorMessages"
        :right-align-label="rightAlign"
        :dirty="dirty"
    >
        <input
            :name="name"
            :value="value"
            :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty, 'right-align': rightAlign }"
            type="text"
            class="uk-input uk-display-inline"
            autocomplete="off"
            :disabled="disabled"
            :maxlength="maxLength"
            @blur="$emit('blur', $event)"
            @focus="$emit('focus', $event)"
            @input="input($event)"
        />
    </InputBase>
</template>

<script>
import InputBase from './input-base'
export default {
    name: 'GL-TextInput',
    components: {
        InputBase,
    },
    data() {
        return {
            dirty: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        numeric: {
            type: Boolean,
            default: false,
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
        rightAlign: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Uint32Array,
            default: 524288,
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    methods: {
        input(event) {
            if (this.uppercase) {
                event.target.value = event.target.value.toUpperCase()
            }
            if (this.numeric) {
                event.target.value = event.target.value.replace(/[^0-9]/g, '')
            }
            if (event.target.value !== '') {
                this.dirty = true
            }
            this.$emit('input', event.target.value)
        },
    },
}
</script>
