<template>
    <div class="uk-display-inline">
        <div :uk-spinner="'ratio: 1'" class="uk-display-inline"></div>
    </div>
</template>

<script>
export default {
    name: 'GL-Spinner',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        animationFile: {
            type: String,
            default: 'loader_3.json',
        },
    },
}
</script>
