<template>
    <div>
        <Documents :industry="$store.state.txCreate.transaction.transaction.industry" />
        <CreateTxNavButtons
            :disable-next="$store.state.txCreate.documents.loading"
            anaylticsDataId="link_content_create transaction"
            anayliticsNextDataText="step 5| next button click"
            anayliticsBackDataText="step 5| back button click"
            anayliticsCancelDataText="step 5| cancel button click"
        />
    </div>
</template>

<script>
import Documents from './documents'
import CreateTxNavButtons from '../common/create-transaction-navigation'

export default {
    name: 'DocumentsTransactionCreate',
    mounted() {
        this.$setVirtualPageView(
            'home',
            'home:escrow create transaction:step 5 documents',
            'home:escrow create transaction',
            'home:escrow create transaction:step 5 documents',
        )
    },
    components: { CreateTxNavButtons, Documents },
}
</script>
