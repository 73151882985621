export const industries = [
    {
        name: 'General Goods & Services',
        value: 'GENERAL_GOODS_SERVICES',
        icon: 'shopping-bag-solid.svg',
        description: "Any sort of goods or services that don't fit in the other categories",
        formComponentName: 'General',
    },
    {
        name: 'Diesel, Petroleum and Lubricating Oils',
        value: 'FUEL',
        icon: 'gas-pump-solid.svg',
        description: 'Diesel, Petroleum and Lubricating Oils',
        formComponentName: 'Fuel',
    },
    {
        name: 'Mining and Metals',
        value: 'MINING',
        icon: 'truck-pickup-solid.svg',
        description: 'Any sort of goods that does not fit in to the other categories',
        formComponentName: 'MiningAndMetals',
    },
    {
        name: 'Cars, Bikes, Planes & Boats',
        value: 'VEHICLES_WATERCRAFT',
        icon: 'car-solid.svg',
        description: 'Cars, Bikes, Planes & Boats',
        formComponentName: 'Vehicle',
    },
    {
        name: 'Cell phones, Computers & Electronics',
        value: 'CELLPHONES_COMPUTERS',
        icon: 'desktop-solid.svg',
        description: 'Cell phones, Computers & Electronics',
        formComponentName: 'Electronics',
    },
    {
        name: 'Farming Equipment, Livestock & Game',
        value: 'AGRICULTURE_LIVESTOCK_GAME',
        icon: 'tractor-solid.svg',
        description: 'Farming Equipment, Livestock & Game',
        formComponentName: 'Agriculture',
    },
    {
        name: 'Art, Antiques & Collectibles',
        value: 'ART_ANTIQUES_COLLECTIBLES',
        icon: 'palette-solid.svg',
        description: 'Art, Antiques & Collectibles',
        formComponentName: 'Art',
    },
    {
        name: 'Building Renovations, Alterations and Other Related Activities',
        value: 'CONSTRUCTION',
        icon: 'hard-hat-solid.svg',
        description: 'Renovations, Alterations and Other Related Activities',
        formComponentName: 'Construction',
    },
    {
        name: 'Freelancing and Contract Work',
        value: 'CONTRACT_WORK_FREELANCING',
        icon: 'business-time-solid.svg',
        description: 'Freelancing and Contract Work',
        formComponentName: 'Freelancing',
    },
    {
        name: 'Company Mergers and Acquisitions',
        value: 'MERGERS_ACQUISITIONS',
        icon: 'briefcase-solid.svg',
        description: 'Company mergers and Acquisitions',
        formComponentName: 'Mergers',
    },
    {
        name: 'Photography and File Productions',
        value: 'FILMS_PRODUCTION',
        icon: 'camera-retro-solid.svg',
        description: 'Photography and File Productions',
        formComponentName: 'Film',
    },
    {
        name: 'Weddings, Functions and Other events',
        value: 'EVENTS',
        icon: 'calendar-day-solid.svg',
        description: 'Weddings, Functions and Other events',
        formComponentName: 'Events',
    },
    {
        name: 'Web Domain Purchases & Transfers',
        value: 'SOFTWARE_DEV_WEB_DOMAINS',
        icon: 'globe-solid.svg',
        description: 'Web Domain Purchases & Transfers',
        formComponentName: 'Domains',
    },
    {
        name: 'Residential & Commercial Property Sales',
        value: 'PROPERTY',
        icon: 'home-solid.svg',
        description: 'Residential & Commercial Property Sales',
        formComponentName: 'Property',
    },
]
