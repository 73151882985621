/*
STEP 1 :
/dataLayer.js

STEP 2 :
https://assets.adobedtm.com/45b28ee1a4af/ec8e773851c1/launch-69c2e14ebfae-development.min.js'

STEP 3 :
window._satellite.pageBottom()
*/

if (window._satellite) {
    try {
        window._satellite.pageBottom()
    } catch (error) {
        this.$errorHandler().handle(error)
    }
}
