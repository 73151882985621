<template>
    <div @click="cardSelect" class="card" :disabled="disabled" :class="{ selected: selected }">
        <div>
            <GL-Icon :path="iconPath" :size="iconSize" class="icon" />
            <div class="text">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardButton',
    props: {
        text: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        iconPath: {
            type: String,
            default: 'times.svg',
        },
        iconSize: {
            type: Number,
            default: 3,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        cardSelect() {
            if (this.disabled) {
                return
            }
            this.$emit('select', this.text)
        },
    },
}
</script>
<style scoped>
.icon {
    font-size: 3rem;
    margin-bottom: 2.5rem;
    display: inline-block;
    margin: 0.5rem;
}
.card {
    color: #07274a;
    width: 12.5rem;
    height: 12.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: var(--transition-normal);
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 640px) {
    .card {
        width: 8rem;
        height: 8rem;
    }
}
.card[disabled] {
    opacity: 0.5;
    background: lightgrey;
}
.card:hover {
    cursor: pointer;
}

.text {
    font-size: 0.9rem;
    text-transform: uppercase;
}
.description-outer {
    display: flex;
    justify-content: center;
    height: 100%;
    margin-top: 1rem;
}
.description {
    /*padding-top: 1rem;*/
    padding-right: 1rem;
    font-size: 0.9rem;
}
.card.selectable:hover {
    cursor: pointer;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
}
.selected {
    background: linear-gradient(311.11deg, #ffb94f 0%, #ffb24a 6.96%, #ffae46 11.96%, #ff5a00 100%);
    color: white;
    box-shadow: none;
    transition: all 0.2s ease-in;
}
</style>
