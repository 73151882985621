<template>
    <div>
        <div class="uk-flex uk-flex-between uk-flex-wrap uk-margin">
            <div><h4>Parties involved in this transaction</h4></div>
            <div>
                <GL-Button
                    v-if="showEdit"
                    text="Edit"
                    type="primary-outlined"
                    @click="$router.push(`/transaction/${transaction.id}/parties`)"
                />
            </div>
        </div>
        <div v-for="party in transaction.parties" :key="party.id">
            <div class="outline">
                <PartyCard :party="party" :industry="transaction.industry" />
            </div>
        </div>
    </div>
</template>
<script>
import PartyCard from '@/modules/common/components/party-card'

export default {
    components: { PartyCard },
    props: {
        transaction: {
            type: Object,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style scoped>
.outline {
    border-bottom-style: solid;
    border-bottom-width: 0.08rem;
    border-bottom-color: #ddd;
}
</style>
