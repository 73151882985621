/**
 * The point of this mixin is to share commonly needed functionality relating to
 * the party of a transaction. The mixin consists entirely of methods where
 * the party is passed in. This allows it to be used anywhere, even if there
 * isn't a transaction object available.
 *
 * For example in the transaction creation process we don't have a transaction
 * object and thus we construct a dummy object in some places. We can still use
 * these helper functions in that case.
 *
 * Please avoid adding any compute properties here as that will impose
 * requirements on the mixin.
 *
 * Avoid returning literal strings and rather build a set of booleans. We don't
 * want literal strings scattered throughout the project which would need to
 * be changed everywhere if renamed.
 *
 * TODO: Long term we probably want to build a party service so that these helpers
 * can be used in JS files as well and not strictly as a mixin
 */

var mixinParty = {
    methods: {
        partyAcceptance(party) {
            if (!party.acceptance) {
                return false
            }
            return party.acceptance.accepted
        },
        // Party Type *************************************
        partyIsUser(party) {
            if (!party.details) {
                return false
            }
            if (party.details.organization) {
                return false
            }
            return true
        },
        partyIsOrganization(party) {
            if (!party.details) {
                return false
            }
            if (!party.details.organization) {
                return false
            }
            return true
        },
        partyIsReadOnly(party) {
            console.log(party)
        },
        partyIsBuyer(party) {
            console.log(party)
        },
        partyIsSeller(party) {
            console.log(party)
        },
        partyIsOwner(party) {
            console.log(party)
        },
        // Party Info *************************************
        partyName(party) {
            console.log(party)
        },
        // Party Status *************************************
        partyHasAccepted(party) {
            console.log(party)
        },
    },
}

export default mixinParty
