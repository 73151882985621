import config from '../../../config'
import errorHandler from '../error-handler'
import API from '_shared/api/api'
import ApolloClientWrapper from '_shared/apollo-client'
import SBSA from './sbsa'
import APISBSAUser from './sbsa-user'
import SBSAAuth from './sbsa-api-auth'

// Instantiate Apollo Client
const clientWrapper = new ApolloClientWrapper(config.graphURL)

// Catch apollo errors
clientWrapper.errorHandler = function (errors) {
    errorHandler.setApolloErrors(errors)
}

// Instantiate API
const api = new API(config, clientWrapper)
api.auth = new SBSAAuth(api)

// Sign out on authentication error
errorHandler.authenticationError = function () {
    window.location = '/login'
}

// We extend the shared api with the standard bank specific queries and mutations here.
api.sbsa = new SBSA(api)
api.sbsaUser = new APISBSAUser(api)

export default api
