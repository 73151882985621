<template>
    <div>
        <div class="uk-container">
            <div class="uk-flex uk-flex-center">
                <div
                    class="uk-card uk-card-default uk-card-body uk-width-large uk-margin-large-bottom uk-margin-large-top"
                >
                    <div class="uk-text-center">
                        <GL-Spinner :size="8" />
                    </div>

                    <h2 class="uk-text-center">Authentication</h2>
                    <h4 class="uk-text-center">You will be signed in shortly</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/scripts/api/api'
export default {
    name: 'Authentication',
    methods: {
        login() {
            api.auth.challenge()
        },
        register() {
            api.auth.register()
        },
    },
    mounted() {
        this.login()
    },
}
</script>
