import UserCreate from './graphql/user/user-create.gql'
import UserRegister from './graphql/user/user-register.gql'
import UserLoginRequest from './graphql/user/user-login-request.gql'
import UserLogin from './graphql/user/user-login.gql'
import UserPasswordForgot from './graphql/user/user-password-forgot.gql'
import UserPasswordReset from './graphql/user/user-password-reset.gql'
import UserPasswordChange from './graphql/user/user-password-change.gql'
import UserVerificationRequest from './graphql/user/user-verification-request.gql'
import UserVerificationCheck from './graphql/user/user-verification-check.gql'

export default class APISBSAUser {
    constructor(api) {
        this.api = api
    }

    async userCreate(createUserInput) {
        const response = await this.api.client.query({
            query: UserCreate,
            variables: {
                input: createUserInput,
            },
        })
        return response.data.userCreate
    }

    async userRegister(code) {
        const response = await this.api.client.query({
            query: UserRegister,
            variables: {
                code,
            },
        })
        return response.data.userRegister
    }

    async userLogin(userLoginInput) {
        const response = await this.api.client.query({
            query: UserLogin,
            variables: {
                input: userLoginInput,
            },
        })
        return response.data.userLogin
    }

    async userLoginRequest(userLoginRequestInput) {
        const response = await this.api.client.query({
            query: UserLoginRequest,
            variables: {
                input: userLoginRequestInput,
            },
        })
        return response.data.userLoginRequest
    }

    async userPasswordForgot(email) {
        const response = await this.api.client.query({
            query: UserPasswordForgot,
            variables: {
                email,
            },
        })
        return response.data.userPasswordForgot
    }

    async userPasswordReset(input) {
        const response = await this.api.client.query({
            query: UserPasswordReset,
            variables: {
                input,
            },
        })
        return response.data.userPasswordReset
    }

    async userPasswordChange(input) {
        const response = await this.api.client.mutate({
            mutation: UserPasswordChange,
            variables: {
                input,
            },
        })
        return response.data.userPasswordChange
    }

    async verificationRequest(input) {
        const response = await this.api.client.mutate({
            mutation: UserVerificationRequest,
            variables: {
                input,
            },
        })
        return response.data.verificationRequest
    }

    async verificationCheck(input) {
        const response = await this.api.client.mutate({
            mutation: UserVerificationCheck,
            variables: {
                input,
            },
        })
        return response.data.verificationCheck
    }
}
