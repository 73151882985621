<template>
    <div class="uk-flex uk-flex-center uk-animation-slide-top-small">
        <div class="uk-card uk-card-default uk-card-body uk-width-5-6@m">
            <div uk-grid>
                <GL-CurrencyInput
                    v-model="value"
                    label="Transaction Amount"
                    class="uk-width-1-3@m"
                    :error-messages="validation.allocationValue(value)"
                />

                <GL-NumberInput
                    class="uk-width-1-3@m uk-width-1-2@s"
                    label="Number of days for delivery"
                    right-align
                    tooltip="Number of days for delivery is the expected duration for the delivery of the goods or services"
                    v-model="daysToDeliver"
                    :error-messages="validation.requiredField(daysToDeliver)"
                />
                <GL-NumberInput
                    class="uk-width-1-3@m uk-width-1-2@s"
                    label="Number of days for inspection"
                    right-align
                    tooltip="Number of days for inspection is the agreed period of days a BUYER has to inspect the goods or services"
                    v-model="daysToInspect"
                    :error-messages="validation.requiredField(daysToDeliver)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import validation from '@/scripts/transaction-create-field-validation'
export default {
    name: 'SinglePaymentCard',
    data() {
        return {
            validation,
        }
    },
    computed: {
        value: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].value
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: 0, key: 'value', value: v })
            },
        },
        validateValue() {
            return []
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].daysToDeliver
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: 0, key: 'daysToDeliver', value: v })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].daysToInspect
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: 0, key: 'daysToInspect', value: v })
            },
        },
    },
}
</script>
