<template>
    <div class="uk-flex" :class="cardClass">
        <div class="uk-card uk-card-default uk-card-body uk-padding-small uk-width-4-5 uk-width-3-5@m uk-margin-small">
            <div class="uk-flex uk-flex-left header">
                <div class="uk-width-expand">
                    <div class="uk-flex uk-flex-between">
                        <div class="uk-width-expand">
                            <strong> {{ comment.owner.name }} </strong>
                        </div>
                        <div class="uk-label">
                            {{ comment.owner.role }}
                        </div>
                    </div>
                    <div class="uk-width-expand">
                        {{ comment.createdAt | dateTime }}
                    </div>
                </div>
            </div>
            <div class="uk-width-expand body">
                {{ comment.body }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        comment: {
            type: Object,
        },
    },
    computed: {
        cardClass() {
            return {
                'uk-flex-right': this.comment.owner.activeUser,
            }
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 48px;
    height: 48px;
}
.header {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--tradesafe-grey-lighter);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}
.body {
    white-space: pre-wrap;
}
</style>
