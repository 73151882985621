import 'core-js/features/array/includes'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'uikit'
import '@/scripts/tours/vue-tour'
import 'cropperjs/dist/cropper.min.css'
import '@/scss/animate.css'
import '@/scripts/filters'
import '@/scripts/global-components'
import '@/modules/common/mixin-global'
import '@/scripts/analytics'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Vue.config.productionTip = false

Sentry.init({
    Vue,
    dsn: 'https://60d69662f13d4ac0a907eeabeaaae8a1@o344503.ingest.sentry.io/4376637',
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [
                'localhost',
                'securepayementsit.standardbank.co.za',
                'escrow.standardbank.co.za',
                /^\//,
            ],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
