<template>
    <button
        @click="click()"
        :class="'uk-button uk-button-' + type"
        :disabled="disabled || loading"
        :uk-tooltip="tooltip"
    >
        <GL-Spinner v-if="loading" class="uk-margin-small-right" />
        <span class="uk-text-nowrap">{{ computedText }} </span>
    </button>
</template>

<script>
export default {
    name: 'GL-Button',
    props: {
        type: {
            // default, primary, secondary, danger
            type: String,
            default: 'default',
        },
        text: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledText: {
            type: String,
            default: '',
        },

        loading: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
            default: '',
        },
    },
    computed: {
        computedText() {
            if (this.loading) {
                return this.loadingText
            }
            if (this.disabled && this.disabledText) {
                return this.disabledText
            }
            return this.text
        },
    },
    methods: {
        click() {
            this.$emit('click')
        },
    },
}
</script>

<style scoped>
.uk-button-warning {
    background-color: #faa05a;
    color: white;
}
.uk-button-warning:disabled {
    background-color: transparent;
    color: grey;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
}
</style>
