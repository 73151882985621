import api from '@/scripts/api/api'

const userLoginSchema = {
    email: '',
    password: '',
}

const userCreateSchema = {
    mobile: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    terms: false,
    newsletter: true,
}

const userPasswordResetSchema = {
    mobile: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    otp: '',
}

export default {
    namespaced: true,
    state: {
        userLogin: {
            ...userLoginSchema,
        },
        userCreate: {
            ...userCreateSchema,
        },
        passwordReset: {
            ...userPasswordResetSchema,
        },
    },
    mutations: {
        setUserCreateField(state, payload) {
            state.userCreate[payload.key] = payload.value
        },
        setUserLoginField(state, payload) {
            state.userLogin[payload.key] = payload.value
        },
        clearData(state) {
            state.userCreate = { ...userCreateSchema }
            state.userLogin = { ...userLoginSchema }
            state.passwordReset = { ...userPasswordResetSchema }
        },
        setPasswordResetField(state, payload) {
            state.passwordReset[payload.key] = payload.value
        },
    },
    actions: {
        async userCreate({ state }) {
            await api.sbsaUser.userCreate(state.userCreate)
        },
        async userRegister({ commit }, payload) {
            const token = await api.sbsaUser.userRegister(payload.code)
            api.auth.setToken(token)
            commit('clearData')
        },
        async userLoginRequest({ state }) {
            return await api.sbsaUser.userLoginRequest({
                username: state.userLogin.email,
                password: state.userLogin.password,
            })
        },
        async userLogin({ state, commit }, payload) {
            const token = await api.sbsaUser.userLogin({
                password: state.userLogin.password,
                username: state.userLogin.email,
                code: payload.code,
            })
            api.auth.setToken(token)
            commit('clearData')
        },
        async requestPasswordReset({ commit, state }) {
            const response = await api.sbsaUser.userPasswordForgot(state.passwordReset.email)
            commit('setPasswordResetField', { key: 'mobile', value: response.mobile })
        },
        async resetPassword({ commit, state }) {
            const success = await api.sbsaUser.userPasswordReset({
                email: state.passwordReset.email,
                password: state.passwordReset.password,
                passwordConfirmation: state.passwordReset.passwordConfirmation,
                verificationToken: state.passwordReset.otp,
            })
            if (success) {
                commit('clearData')
            }
            return success
        },
    },
}
