<template>
    <InputBase :label="label" :tooltip="tooltip" :error-messages="errorMessages" :dirty="dirty">
        <vue-tel-input
            :name="name"
            :value="value"
            @input="input"
            mode="national"
            autocomplete="off"
            :disabled="disabled"
            :validCharactersOnly="true"
            inputClasses="uk-input"
            :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty }"
        >
        </vue-tel-input>
    </InputBase>
</template>

<script>
import InputBase from './input-base'
export default {
    components: {
        InputBase,
    },
    data() {
        return {
            dirty: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    methods: {
        input(event, obj) {
            if (obj.number.input) {
                this.dirty = true
            }
            if (obj.number.e164) {
                this.$emit('input', obj.number.e164)
            } else {
                this.$emit('input', obj.number.input)
            }
        },
    },
}
</script>
<style scoped>
.vue-tel-input:focus {
    box-shadow: none;
    border-color: rgb(30, 135, 240);
    outline: none;
}
.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: rgb(30, 135, 240);
}
.vue-tel-input {
    border-color: rgb(229, 229, 229);
    border-radius: 0px;
}
</style>
