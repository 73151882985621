import { render, staticRenderFns } from "./overview-commentary.vue?vue&type=template&id=6b533ee9&scoped=true&"
import script from "./overview-commentary.vue?vue&type=script&lang=js&"
export * from "./overview-commentary.vue?vue&type=script&lang=js&"
import style0 from "./overview-commentary.vue?vue&type=style&index=0&id=6b533ee9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b533ee9",
  null
  
)

export default component.exports