<template>
    <ModalBase ref="cancel-modal">
        <div>
            <h4>Save Changes?</h4>
            <GL-ErrorAlert :errorMessage="errorMessage" />
            <div class="uk-margin-top">
                <p class="uk-padding">Would you like us to save this transaction for future use?</p>
                <hr />
                <div class="uk-flex uk-flex-between uk-flex-right uk-flex-wrap-reverse">
                    <GL-Button
                        @click="hide"
                        text="Cancel"
                        type="default uk-button-small"
                        name="cancel-cancel-transaction"
                        :class="{
                            'uk-margin-small-right': $screenLarger('small'),
                            'uk-width-1-1': $screenSmaller('small'),
                        }"
                    />
                    <div :class="{ 'uk-width-1-1': $screenSmaller('small') }">
                        <GL-Button
                            :class="{
                                'uk-margin-small-right': $screenLarger('small'),
                                'uk-width-1-1': $screenSmaller('small'),
                                'uk-margin-small': $screenSmaller('small'),
                            }"
                            type="secondary uk-button-small"
                            name="cancel-do-not-save-transaction"
                            text="no"
                            :loading="loading"
                            @click="deleteTransaction"
                        />
                        <GL-Button
                            :class="{
                                'uk-margin-small-right': $screenLarger('small'),
                                'uk-width-1-1': $screenSmaller('small'),
                                'uk-margin-small': $screenSmaller('small'),
                            }"
                            type="primary uk-button-small"
                            text="yes"
                            name="cancel-save-transaction"
                            @click="saveTransaction"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ModalBase>
</template>
<script>
import ModalBase from '@/modules/common/components/modal-base'
export default {
    name: 'CancelTransactionCreateModal',
    components: { ModalBase },
    data() {
        return {
            loading: false,
            errorMessage: null,
        }
    },
    computed: {
        transaction() {
            return this.$store.state.transaction.transaction
        },
        stepName() {
            return this.$store.getters['txCreate/stepName']
        },
    },
    methods: {
        hide() {
            this.$refs['cancel-modal'].hide()
        },
        show() {
            this.$refs['cancel-modal'].show()
        },
        async deleteTransaction() {
            try {
                this.loading = true
                await this.$store.dispatch('txCreate/deleteTransaction')
                this.$refs['cancel-modal'].hide()
                await this.$router.push('/')
                await this.$store.dispatch('transaction/transactionListFetch')
            } catch (e) {
                this.errorMessage = this.$handleError(e)
            } finally {
                this.loading = false
            }
        },
        async saveTransaction() {
            this.$refs['cancel-modal'].hide()
            await this.$router.push('/')
            await this.$store.dispatch('transaction/transactionListFetch')
        },
    },
}
</script>
