<template>
    <div class="home">
        <div class="uk-container">
            <div class="uk-flex uk-flex-center">
                <div
                    class="uk-card uk-card-default uk-card-body uk-width-large uk-margin-large-bottom uk-margin-large-top"
                >
                    <div class="uk-text-center" v-if="loadingCodeExchange">
                        <GL-Spinner :size="8" />
                    </div>

                    <h2 class="uk-text-center">Authentication</h2>
                    <h4 class="uk-text-center" v-if="loadingCodeExchange">You will be signed in shortly</h4>

                    <GL-ErrorAlert :errorMessage="errorMessageCodeExchange" />
                    <!-- Temporary development code - remove before launch -->
                    <div v-if="errorMessageCodeExchange">
                        <a @click="golocal"> Go Local </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/scripts/api/api'
export default {
    name: 'AuthenticationCallback',
    data() {
        return {
            loadingCodeExchange: false,
            errorMessageCodeExchange: '',
        }
    },
    async mounted() {
        try {
            this.loadingCodeExchange = true
            this.errorMessageCodeExchange = ''
            await api.auth.codeExchange(this.$route.query)
        } catch (error) {
            this.errorMessageCodeExchange = this.$handleError(error)
        } finally {
            this.loadingCodeExchange = false
        }
    },
    components: {},
    methods: {
        // Temporary development code - remove before launch
        golocal() {
            window.location = 'http://localhost:8080/auth/callback' + window.location.search
        },
    },
}
</script>
