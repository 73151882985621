<template>
    <div class="uk-margin-large-bottom">
        <h3>Transaction Details</h3>
        <div v-if="$screenLarger('small')">
            <div class="uk-grid uk-margin-bottom">
                <div v-for="item in tabs" :key="item.value" @click="tab = item.value">
                    <div class="uk-flex uk-flex-between tab-selectable" :class="{ 'tab-selected': tab === item.value }">
                        <div data-id="link_tab_tab" :data-text="item.analyticsDataText">
                            {{ item.name }}
                        </div>
                        <div v-if="item.icon" class="uk-margin-small-right uk-text-warning">
                            <GL-Icon :path="item.icon.path" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="uk-animation-slide-top-small" :key="tab">
                    <component :is="tab" :transaction="transactionPartyFilled" :show-edit="showEdit"></component>
                </div>
            </div>
        </div>
        <div v-if="$screenSmaller('small')">
            <ul uk-accordion>
                <li v-for="item in tabs" :key="item.value">
                    <a class="uk-accordion-title" href="#">{{ item.name }}</a>
                    <div class="uk-accordion-content">
                        <component :is="item.value" :transaction="transactionPartyFilled" :show-edit="showEdit" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import OverviewDetails from '@/modules/common/components/overview/overview-details/overview-details'
import OverviewFees from '@/modules/common/components/overview/overview-fees/overview-fees'
import OverviewParties from '@/modules/common/components/overview/overview-parties'
import OverviewFiles from '@/modules/common/components/overview/overview-files'
import OverviewDelivery from '@/modules/common/components/overview/overview-delivery/overview-delivery'
import OverviewMilestones from '@/modules/common/components/overview/overview-milestones/overview-milestones'
import OverviewCommentary from '@/modules/common/components/overview/overview-commentary/overview-commentary'
import OverviewConfidentiality from '@/modules/common/components/overview/overview-confidentiality'
import OverviewTracking from '@/modules/common/components/overview/overview-tracking'

import mixinTransaction from '@/modules/common/mixin-transaction'

export default {
    name: 'Overview',
    mixins: [mixinTransaction],
    components: {
        OverviewDetails,
        OverviewFees,
        OverviewParties,
        OverviewDelivery,
        OverviewFiles,
        OverviewCommentary,
        OverviewMilestones,
        OverviewConfidentiality,
        OverviewTracking,
    },
    props: {
        showCommentary: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        showEdit() {
            if (this.transaction.metadata && this.transaction.metadata.activeParty.owner) {
                return this.transaction.state === 'INVITATION_SENT'
            }
            return false
        },
        tab: {
            get() {
                return this.$route.query.overviewtab || 'OverviewDetails'
            },
            set(value) {
                if (this.$route.query.overviewtab !== value) {
                    this.$router.replace({
                        route: '/profile',
                        query: {
                            overviewtab: value,
                        },
                    })
                }
            },
        },
        tabs() {
            const tabs = [
                {
                    name: 'General Details',
                    value: 'OverviewDetails',
                    analyticsDataText: 'overview | details',
                },
            ]

            if (this.hasMilestones) {
                let tabName = 'Milestones'

                // if (this.transaction.industry === 'FUEL') {
                //     tabName = 'Loads'
                // }
                // if (this.transaction.industry === 'MINING') {
                //     tabName = 'Loads'
                // }

                tabs.push({
                    name: tabName,
                    value: 'OverviewMilestones',
                    analyticsDataText: 'product offering > milestones',
                })
            }

            tabs.push({
                name: 'Fees and Costs',
                value: 'OverviewFees',
                analyticsDataText: 'overview | fees',
            })

            if (this.transaction.privacy !== 'NONE') {
                tabs.push({
                    name: 'Confidentiality',
                    value: 'OverviewConfidentiality',
                })
            }

            tabs.push({
                name: 'Trade Parties',
                value: 'OverviewParties',
                analyticsDataText: 'overview | parties',
            })
            tabs.push({
                name: 'Delivery Details',
                value: 'OverviewDelivery',
                analyticsDataText: 'overview | delivery',
            })
            if (
                this.transaction.state === 'INITIATED' ||
                this.transaction.state === 'GOODS_ACCEPTED' ||
                this.transaction.state === 'DELIVERED' ||
                this.transaction.state === 'FUNDS_RELEASED' ||
                this.transaction.state === 'DISPUTED'
            ) {
                if (this.hasTrackingInformation) {
                    tabs.push({
                        name: 'Tracking Information',
                        value: 'OverviewTracking',
                        analyticsDataText: 'overview | tracking',
                    })
                }
            }

            tabs.push({
                name: 'Files',
                value: 'OverviewFiles',
                analyticsDataText: 'overview | files',
                icon: this.hasNewFiles
                    ? {
                          path: 'exclamation-circle-solid.svg',
                          tooltip: 'There are new files to review',
                      }
                    : undefined,
            })

            if (this.showCommentary) {
                tabs.push({
                    name: 'Commentary',
                    value: 'OverviewCommentary',
                    analyticsDataText: 'product offering > commentary',
                    icon: this.hasNewComments
                        ? {
                              path: 'exclamation-circle-solid.svg',
                              tooltip: 'There are new comments to review',
                          }
                        : undefined,
                })
            }

            return tabs
        },
    },
}
</script>
<style lang="scss" scoped>
.uk-accordion-title {
    font-size: 1rem;
}
</style>
