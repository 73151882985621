import Vue from 'vue'
import Vuex from 'vuex'

import organization from '_shared/store/store-organization'
import escrowAccount from '_shared/store/store-escrow-account'
import transaction from '_shared/store/store-transaction'
import txCreate from '_shared/store/store-transaction-create'
import context from '_shared/store/store-context'
import user from '_shared/store/store-user'
import tour from '_shared/store/store-tour'

import sbsaUser from './store-sbsa-user'

import api from '@/scripts/api/api'

Vue.use(Vuex)

const store = new Vuex.Store({
    actions: {},
    getters: {},
    modules: {
        txCreate,
        organization,
        user,
        context,
        escrowAccount,
        transaction,
        tour,
        sbsaUser,
    },
})

// Inject some SBSA specific data into the store
store.state.organization.organizationFetchInjections = [
    {
        keyName: 'directorsCheck',
        method: async function (organization) {
            return await api.sbsa.directorsOrganizationQuery(organization.id)
        },
    },
]
store.state.transaction.transactionFetchInjections = [
    {
        keyName: 'directorsCheck',
        method: async function (transaction) {
            return await api.sbsa.directorsTransactionQuery(transaction.metadata.activeParty.id)
        },
    },
]

export default store
