<template>
    <div>
        <div class="uk-container">
            <WelcomeCard />
        </div>

        <TransactionList />

        <MymoCardBusiness v-if="this.$store.state.context.actingAsBusiness" />
        <MymoCard v-if="!this.$store.state.context.actingAsBusiness" />
        <!-- DONT DELETE THIS DIV IT'S REQUIRED FOR THE TOUR -->
        <div v-if="loadingTour"></div>
        <Tour />
    </div>
</template>

<script>
import WelcomeCard from '../components/welcome-card'
import MymoCard from '../components/mymo-card'
import MymoCardBusiness from '../components/mymo-business-card'
import TransactionList from '../components/transaction-list'

import Tour from '../../common/components/tour'
export default {
    name: 'Home',
    components: {
        Tour,
        TransactionList,
        WelcomeCard,
        MymoCardBusiness,
        MymoCard,
    },
    data() {
        return {
            selectedTab: null,
            componentMounted: false,
        }
    },
    mounted() {
        if (this.$route.query.ozow === 'true') {
            setTimeout(() => {
                this.$globalEventEmit('notification', {
                    type: 'success',
                    title: 'Success!',
                    body: 'You have made payment to Escrow using Ozow and your escrow account balances have been updated',
                })
            }, 1000)
        }
        this.$store.commit('transaction/setTransactionListRefetch', true)
        this.$setVirtualPageView('Home')
    },
    computed: {
        context() {
            return this.$store.getters['context/context']
        },
        user() {
            return this.$store.getters['user/user']
        },
        loadingTour() {
            if (this.context === '__loading__' || this.user === '__loading__') {
                return true
            }
            if (this.$route.query.tour === 'yes') {
                this.$getTourService().markCreateSeen(false)
                this.$getTourService().markProfileSeen(false)
                this.$getTourService().markHomeSeen(false)
            }
            if (this.$getTourService().hasSeenHome() === false) {
                this.$getTourService().markHomeSeen(true)
                if (this.$screenLarger('medium')) {
                    window.scrollY = 0
                    this.$tours['postRegistrationTour'].start()
                }
            }
            return false
        },
    },
}
</script>

<style lang="scss">
@import '@/scss/pages/home.scss';
</style>
