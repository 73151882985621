<template>
    <footer class="footer">
        <div class="uk-container" style="color: white">
            <div uk-grid class="uk-margin-bottom">
                <div class="uk-width-1-1 uk-width-2-3@s">
                    <p>
                        <a
                            href="/documents/Standard_Banks_Escrow_Terms_and_Conditions_13_11_2020.pdf"
                            target="_blank"
                            style="color: white"
                        >
                            Terms and Conditions
                        </a>
                        <a
                            href="https://www.standardbank.co.za/southafrica/personal/about-us/legal/privacy-statement"
                            target="_blank"
                            class="uk-margin-left"
                            style="color: white"
                        >
                            Privacy Policy
                        </a>
                        <!-- OneTrust Cookies Settings button start -->
                        <button
                            id="ot-sdk-btn"
                            class="ot-sdk-show-settings"
                            style="
                                color: white !important;
                                background-color: transparent !important;
                                border: none !important;
                                font-size: 15px !important;
                                font-weight: 500 !important;
                                font-family: 'Benton Sans', Arial, Tahoma, sans-serif !important;
                                margin: 0 0 0 20px !important;
                                padding: 0 !important;
                                text-transform: capitalize !important;
                            "
                        >
                            Cookie Settings
                        </button>
                        <!-- OneTrust Cookies Settings button end -->
                    </p>
                </div>
                <div class="uk-width-1-1 uk-width-1-3@s uk-text-right@s">
                    <a
                        href="https://www.linkedin.com/company/standard-bank-south-africa/"
                        target="_blank"
                        uk-icon="linkedin"
                        class="uk-margin-small-right"
                    ></a>
                    <a
                        href="https://www.youtube.com/channel/UC_oz5no5MIucu45I4PO9nCQ"
                        target="_blank"
                        uk-icon="youtube"
                        class="uk-margin-small-right"
                    ></a>
                    <a
                        href="https://www.facebook.com/StandardBankSA"
                        target="_blank"
                        uk-icon="facebook"
                        class="uk-margin-small-right"
                    ></a>
                    <a
                        href="https://twitter.com/standardbankza"
                        target="_blank"
                        uk-icon="twitter"
                        class="uk-margin-small-right"
                    >
                    </a>
                </div>
            </div>
            <p style="font-weight: 300">
                Standard Bank is a licensed financial services provider in terms of the Financial Advisory and
                Intermediary Services Act and registered credit provider in terms of the National Credit Act,
                registration number NCRCP15
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style scoped>
.footer {
    padding: 1rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #0a2240;
    color: rgb(180, 180, 180);
}
</style>
