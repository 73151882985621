<template>
    <div>
        <div class="uk-text-center uk-margin-large-bottom">
            <h4 class="uk-text-secondary">
                {{ heading }}
            </h4>
            <p>Where must delivery take place ?</p>
        </div>
        <div
            class="uk-margin uk-flex uk-flex-center uk-flex-wrap"
            :class="{ 'uk-margin-large-bottom': !deliveryAddressRequired }"
        >
            <CardButton
                icon-path="truck-solid.svg"
                text="Delivery Required"
                class="selectable-card"
                :selected="deliveryAddressRequired === true"
                @select="deliveryAddressRequired = true"
            />
            <CardButton
                icon-path="handshake-solid.svg"
                text="Will arrange privately"
                class="selectable-card"
                :selected="deliveryAddressRequired === false"
                @select="deliveryAddressRequired = false"
            />
        </div>

        <div class="uk-flex uk-flex-center uk-animation-slide-right-small" v-if="deliveryAddressRequired">
            <AddressForm class="uk-width-3-4@m" />
        </div>
        <CreateTxNavButtons
            :disable-next="!allowProgress"
            anaylticsDataId="link_content_create transaction"
            anayliticsNextDataText="step 4| next button click"
            anayliticsBackDataText="step 4| back button click"
            anayliticsCancelDataText="step 4| cancel button click"
        />
    </div>
</template>

<script>
import CardButton from '@/modules/common/components/input/card-button'
import AddressForm from '@/modules/transaction/components/transaction-create/delivery/address-form'
import CreateTxNavButtons from '../common/create-transaction-navigation'
export default {
    name: 'GeneralDelivery',
    components: { CardButton, AddressForm, CreateTxNavButtons },
    mounted() {
        this.$setVirtualPageView(
            'home',
            'home:escrow create transaction:step 4 delivery',
            'home:escrow create transaction',
            'home:escrow create transaction:step 4 delivery',
        )
    },
    computed: {
        industry() {
            return this.$store.state.txCreate.transaction.transaction.industry
        },
        heading() {
            return 'DELIVERY OPTIONS'
        },
        deliveryAddressRequired: {
            get() {
                return this.$store.state.txCreate.address.deliveryAddressRequired
            },
            set(v) {
                this.$store.commit('txCreate/deliveryAddressRequired', v)
            },
        },
        allowProgress() {
            if (this.deliveryAddressRequired) {
                return (
                    this.$store.state.txCreate.address.address.name &&
                    this.$store.state.txCreate.address.address.line1 &&
                    this.$store.state.txCreate.address.address.line2 &&
                    this.$store.state.txCreate.address.address.city &&
                    this.$store.state.txCreate.address.address.area &&
                    this.$store.state.txCreate.address.address.code
                )
            }
            return true
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem;
    padding: 1rem;
}
</style>
