<template>
    <div class="navbar">
        <!-- Top -->
        <nav class="navbar-top uk-navbar-container uk-margin">
            <div class="uk-container">
                <div class="uk-flex uk-flex-between">
                    <div class="home-tour-step-1 selectable" @click="$getApi().user.signedIn() ? navigate('/') : null">
                        <div style="padding-top: 18px;">
                            <img src="/images/sbsa_logo.svg" width="200" />
                        </div>
                    </div>
                    <div>
                        <!-- Desktop -->
                        <div v-if="!$getApi().user.signedIn()">
                            <!-- Not Signed In -->
                            <div class="uk-flex uk-flex-right" v-if="$screenLarger('small')">
                                <div
                                    class="item-top uk-margin-left uk-margin-right"
                                    :class="{ 'item-top-selected': $route.name === 'Register' }"
                                    @click="navigate('/register')"
                                >
                                    <div class="uk-margin-left uk-margin-right">
                                        <GL-Icon path="register-light.svg" />
                                        Register
                                    </div>
                                </div>
                                <div
                                    class="item-top uk-margin-left uk-margin-right"
                                    :class="{ 'item-top-selected': $route.name === 'Login' }"
                                    @click="navigate('/login')"
                                >
                                    <div class="uk-margin-left uk-margin-right">
                                        <GL-Icon path="user-light.svg" />
                                        Sign In
                                    </div>
                                </div>
                                <div
                                    class="item-top uk-margin-left uk-margin-right"
                                    :class="{ 'item-top-selected': $route.name === 'Support' }"
                                    @click="navigate('/support')"
                                >
                                    <div class="uk-margin-left uk-margin-right">
                                        <GL-Icon path="comment-light.svg" />
                                        Contact
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="$getApi().user.signedIn()">
                            <!-- Signed In -->
                            <div v-if="context === '__loading__'">
                                <div class="item-top">
                                    <!-- GL-Spinner / -->
                                </div>
                            </div>
                            <div v-if="context !== '__loading__'">
                                <div v-if="$screenLarger('medium')" class="unselectable">
                                    <div class="uk-flex uk-flex-right">
                                        <div
                                            class="item-top"
                                            :class="{ 'item-top-selected': actingAsBusiness === false }"
                                            @click="actingAsBusiness = false"
                                            data-id="link_content_content link"
                                            data-text="My Personal Escrow Link Click"
                                        >
                                            My Personal Escrow
                                        </div>
                                        <div
                                            class="item-top home-tour-step-3"
                                            :class="{ 'item-top-selected': actingAsBusiness === true }"
                                            @click="actingAsBusiness = true"
                                            data-id="link_content_content link"
                                            data-text="My Business Escrow Link Click"
                                        >
                                            My Business Escrow
                                        </div>

                                        <div class="item-top">
                                            <div data-id="link_content_content link" data-text="Username Click">
                                                <span class="uk-margin-small-right"> | </span>
                                                <GL-Icon class="uk-margin-small" path="user.svg" />
                                                {{ context.name }}
                                                <GL-Icon
                                                    class="uk-margin-small"
                                                    path="chevron-down-solid.svg"
                                                    :size="1"
                                                />
                                            </div>
                                            <div uk-dropdown="mode: click">
                                                <ul class="uk-nav uk-dropdown-nav">
                                                    <li
                                                        @click="signOut"
                                                        data-id="link_content_content link"
                                                        data-text="Sign Out Click"
                                                    >
                                                        <GL-Icon
                                                            path="sign-out-alt-solid.svg"
                                                            class="uk-margin-small-left uk-margin-small-right"
                                                        />
                                                        Sign Out
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mobile -->
                        <div v-if="$screenSmaller('medium')" style="margin-top: 28px;" class="uk-margin-right">
                            <div class="hamburger unselectable" @click.prevent="toggle()">
                                <GL-Icon class="uk-margin-left" path="bars.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <!-- Bottom -->
        <div style="margin-bottom: 2rem;" v-if="menuAvaliable">
            <!-- Desktop -->
            <div v-if="context !== '__loading__' && $screenLarger('medium')" class="navbar-bot uk-margin-bottom">
                <div class="uk-container unselectable">
                    <div class="uk-flex uk-flex-left">
                        <div
                            class="item-bot"
                            :class="{ 'item-bot-selected': $route.name === 'Home' }"
                            @click="navigate('/')"
                        >
                            <GL-Icon path="home.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Home
                        </div>
                        <div
                            class="item-bot home-tour-step-2 home-tour-step-9"
                            :class="{ 'item-bot-selected': $route.name === 'Profile' }"
                            @click="navigate('/profile')"
                        >
                            <GL-Icon path="user.svg" class="uk-margin-small-left uk-margin-small-right" />
                            My Profile
                            <span
                                v-if="profileIcon"
                                class="uk-margin-small-left uk-text-warning"
                                :uk-tooltip="profileIcon.tooltip"
                            >
                                <GL-Icon :path="profileIcon.path" />
                            </span>
                        </div>
                        <div
                            class="item-bot"
                            :class="{ 'item-bot-selected': $route.name === 'EscrowAccount' }"
                            @click="navigate('/escrow-account')"
                        >
                            <GL-Icon path="wallet.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Escrow Account
                        </div>
                        <div
                            class="item-bot"
                            :class="{ 'item-bot-selected': $route.name === 'Support' }"
                            @click="navigate('/support')"
                        >
                            <GL-Icon path="phone.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Contact
                        </div>
                    </div>
                </div>
            </div>
            <!-- Mobile -->
            <div v-if="$screenSmaller('medium') && showBottom" class="uk-margin-right">
                <div v-if="!$getApi().user.signedIn()">
                    <!-- Not Signed In -->
                    <ul class="uk-list uk-list-divider uk-margin-top">
                        <li @click="navigate('/register')">
                            <GL-Icon path="home.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Register
                        </li>
                        <li @click="navigate('/login')">
                            <GL-Icon path="wallet.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Sign In
                        </li>
                        <li @click="navigate('/support')">
                            <GL-Icon path="phone.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Contact
                        </li>
                    </ul>
                </div>
                <div v-if="$getApi().user.signedIn()">
                    <!-- Signed In -->
                    <div class="mobile-context">
                        <div
                            class="item-mobile-context"
                            :class="{ 'item-mobile-context-selected': actingAsBusiness === false }"
                            @click="actingAsBusiness = false"
                        >
                            My Personal Escrow
                        </div>
                        <div
                            class="item-mobile-context"
                            :class="{ 'item-mobile-context-selected': actingAsBusiness === true }"
                            @click="actingAsBusiness = true"
                        >
                            My Business Escrow
                        </div>
                    </div>
                    <ul class="uk-list uk-list-divider uk-margin-top">
                        <li @click="navigate('/')">
                            <GL-Icon path="home.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Home
                        </li>
                        <li @click="navigate('/profile')">
                            <GL-Icon path="user.svg" class="uk-margin-small-left uk-margin-small-right" />
                            My Profile
                            <span
                                v-if="profileIcon"
                                class="uk-margin-small-left uk-text-warning"
                                :uk-tooltip="profileIcon.tooltip"
                            >
                                <GL-Icon :path="profileIcon.path" />
                            </span>
                        </li>
                        <li @click="navigate('/escrow-account')">
                            <GL-Icon path="wallet.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Escrow Account
                        </li>
                        <li @click="navigate('/support')">
                            <GL-Icon path="phone.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Contact
                        </li>
                        <li></li>
                        <li @click="signOut">
                            <GL-Icon path="sign-out-alt-solid.svg" class="uk-margin-small-left uk-margin-small-right" />
                            Sign Out
                        </li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: 'Navbar',
    data() {
        return {
            showBottom: false,
        }
    },
    computed: {
        ...Vuex.mapState({
            context: state => state.context.context
        }),
        profileIcon() {
            if (this.$store.getters['context/contextVerification']) {
                return {
                    path: 'exclamation-circle-solid.svg',
                    tooltip: this.$store.getters['context/contextVerification'].message,
                }
            }
            return null
        },
        actingAsBusiness: {
            get() {
                return this.$store.state.context.actingAsBusiness
            },
            set(value) {
                this.$store.state.context.actingAsBusiness = value

                if (value === true) {
                    if (this.hasBusiness === false) {
                        this.navigate('/business/add')
                    } else {
                        this.$store.dispatch('context/contextSelectBusiness')
                        this.navigate('/')
                    }
                }
                if (value === false) {
                    this.$store.dispatch('context/contextSelectPersonal')
                    this.navigate('/')
                }
            },
        },
        hasBusiness() {
            return this.$store.getters['context/hasBusiness']
        },
        menuAvaliable() {
            if (this.actingAsBusiness === false) {
                return true
            }
            if (this.hasBusiness === true) {
                return true
            }
            return false
        },
    },
    methods: {
        toggle() {
            this.showBottom = !this.showBottom
        },
        navigate(target) {
            if (this.$screenSmaller('small')) {
                this.toggle()
            }
            if (this.$route.path !== target) {
                this.$router.push(target)
            }
        },
        signOut() {
            this.$store.dispatch('user/signOut')
        },
        showTour() {
            if (this.$route.name === 'Home') {
                this.$router.replace({ query: { tour: 'yes' } })
            } else {
                this.$router.push({ path: '/', query: { tour: 'yes' } })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/navbar.scss';
</style>
