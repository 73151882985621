import Vue from 'vue'
import windowSize from '_shared/window-size'
import errorHandler from '@/scripts/error-handler'
import eventBus from '_shared/event-bus'
import * as Filters from '@/scripts/filters'

import IndustryService from '@/modules/common/scripts/service-industry'
import PartyService from '@/modules/common/scripts/service-party'
import TourService from '@/modules/common/scripts/service-tour'
import SeenService from '@/modules/common/scripts/service-seen'
import api from '@/scripts/api/api'

const Mixins = {
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    $testdata: 'mixin message',
                }
            },
            methods: {
                $getApi() {
                    return api
                },
                // Services
                $getIndustryService() {
                    return IndustryService
                },
                $getSeenService() {
                    return SeenService
                },
                $getPartyService() {
                    return PartyService
                },
                $getTourService() {
                    return TourService
                },
                // Adobe Analytics
                $setVirtualPageView(page, pageName = '', pageSubSection1 = '', pageSubSection2 = '') {
                    if (window.dataLayer) {
                        window.dataLayer.pageCategory = page
                        window.dataLayer.pageName = pageName || page
                        window.dataLayer.pageSubSection1 = pageSubSection1 || page
                        if (pageSubSection2) {
                            window.dataLayer.pageSubSection2 = pageSubSection2
                        }
                        window._satellite.track('globalVirtualPageView')
                    }
                },
                $setFormComplete() {
                    if (window.dataLayer) {
                        window.dataLayer.formStatus = 'complete'
                        window.dataLayer.formisSubmitted = true
                        window._satellite.track('globalFormComplete')
                    }
                },
                // Other
                $signedIn() {
                    return api.user.signedIn()
                },
                $getFilters() {
                    return Filters
                },
                $screenLarger(size) {
                    return windowSize.larger(size)
                },
                $screenSmaller(size) {
                    return windowSize.smaller(size)
                },
                $handleError(error) {
                    return errorHandler.handle(error)
                },
                // Global Event System (Use with care!)
                $globalEventEmit(eventName, payload) {
                    eventBus.$emit(eventName, payload)
                },
                $globalEventOn(eventName, callback) {
                    eventBus.$on(eventName, callback)
                },
                $globalEventOff(eventName, callback) {
                    eventBus.$off(eventName, callback)
                },
            },
        })
    },
}
Vue.use(Mixins)
