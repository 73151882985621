<template>
    <div>
        <p>The seller has provided tracking information for this transaction:</p>
        <GL-InfoItem label="Courier Company Name" :value="this.trackingInfo.name" />
        <GL-InfoItem label="Tracking number" :value="this.trackingInfo.code" />
    </div>
</template>
<script>
export default {
    props: {
        transaction: {
            type: Object,
        },
    },
    computed: {
        hasTrackingInformation() {
            if (this.transaction.allocations[0].deliveryInstructions) {
                const deliveryInstructions = JSON.parse(this.transaction.allocations[0].deliveryInstructions)
                if (deliveryInstructions.courier) {
                    return true
                }
            }
            return false
        },
        trackingInfo() {
            if (this.hasTrackingInformation) {
                return JSON.parse(this.transaction.allocations[0].deliveryInstructions).courier
            }
            return {}
        },
    },
}
</script>
