<template>
    <div class="uk-button-group">
        <button
            class="uk-button"
            v-for="option in options"
            :key="option.value"
            @click="select(option.value)"
            :class="{ 'uk-button-primary': option.value === selected }"
        >
            {{ option.text }}
        </button>
    </div>
</template>
<script>
export default {
    name: 'GL-ToggleInput',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: String,
            default: 'OPT1',
        },
    },
    methods: {
        select(option) {
            this.$emit('select', option)
        },
    },
}
</script>
