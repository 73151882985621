export const transactionCreate = {
    roles: [
        {
            displayText: 'Buyer',
            key: 'BUYER',
            icon: 'shopping-cart-solid.svg',
            description: 'I am buying',
        },
        {
            displayText: 'Seller',
            key: 'SELLER',
            icon: 'store-solid.svg',
            description: 'I am selling',
        },
        // {
        //     displayText: 'Lead Agent',
        //     key: 'AGENT',
        //     icon: 'users-solid.svg',
        //     description: 'I Am the Lead Agent, Broker or Wholesaler',
        // },
    ],
    otherRoles: [
        { value: 'Adviser', key: 'BENEFICIARY_ADVISER' },
        { value: 'Consultant', key: 'BENEFICIARY_CONSULTANT' },
        { value: 'Delivery Company', key: 'BENEFICIARY_DELIVERY_COMPANY' },
        { value: 'Financial Institution', key: 'BENEFICIARY_FINANCIAL_INSTITUTION' },
        { value: 'Intermediary', key: 'BENEFICIARY_INTERMEDIARY' },
        { value: 'Legal counsel', key: 'BENEFICIARY_LEGAL_COUNSEL' },
        { value: 'Sub-Agent', key: 'BENEFICIARY_SUB_AGENT' },
        { value: 'Wholesaler', key: 'BENEFICIARY_WHOLESALER' },
        { value: 'Other', key: 'BENEFICIARY_OTHER' },
        { value: 'Read-Only User', key: 'READ_ONLY' },
    ],

    industries: [
        {
            name: 'Electronics',
            value: 'CELLPHONES_COMPUTERS',
            icon: 'desktop-solid.svg',
            description: 'Cell phones, Computers & electronics',
            formComponentName: 'Electronics',
        },
        {
            name: 'Vehicles',
            value: 'VEHICLES_WATERCRAFT',
            icon: 'car-solid.svg',
            description: 'Cars, Bikes, Planes & Boats',
            formComponentName: 'Vehicle',
        },
        {
            name: 'Art',
            value: 'ART_ANTIQUES_COLLECTIBLES',
            icon: 'palette-solid.svg',
            description: 'Art, Antiques & Collectibles',
            formComponentName: 'Art',
        },
        {
            name: 'Fuel',
            value: 'FUEL',
            icon: 'gas-pump-solid.svg',
            description: 'Diesel, Petroleum and Lubricating Oils',
            formComponentName: 'Fuel',
        },
        {
            name: 'Mining & Metals',
            value: 'MINING',
            icon: 'truck-pickup-solid.svg',
            description: 'Precious and Non-Precious Metals',
            formComponentName: 'MiningAndMetals',
        },

        {
            name: 'Agriculture',
            value: 'AGRICULTURE_LIVESTOCK_GAME',
            icon: 'tractor-solid.svg',
            description: 'Farming Equipment, Livestock & Game',
            formComponentName: 'Agriculture',
        },

        {
            name: 'Web domains',
            value: 'SOFTWARE_DEV_WEB_DOMAINS',
            icon: 'globe-solid.svg',
            description: 'Web Domain Purchases & Transfers',
            formComponentName: 'Domains',
        },
        {
            name: 'Freelancing',
            value: 'CONTRACT_WORK_FREELANCING',
            icon: 'business-time-solid.svg',
            description: 'Freelancing and Contract Work',
            formComponentName: 'Freelancing',
        },
        {
            name: 'film Production',
            value: 'FILMS_PRODUCTION',
            icon: 'camera-retro-solid.svg',
            description: 'Photography and film Productions',
            formComponentName: 'Film',
        },
        {
            name: 'Construction',
            value: 'CONSTRUCTION',
            icon: 'hard-hat-solid.svg',
            description: 'Buildings, Renovations, Alterations and Other Related Activities',
            formComponentName: 'Construction',
        },

        {
            name: 'Property',
            value: 'PROPERTY',
            icon: 'home-solid.svg',
            description: 'Residential & Commercial property Sales',
            formComponentName: 'Property',
        },
        {
            name: 'Events',
            value: 'EVENTS',
            icon: 'calendar-day-solid.svg',
            description: 'Weddings, Functions and Other events',
            formComponentName: 'Events',
        },
        {
            name: 'M&A',
            value: 'MERGERS_ACQUISITIONS',
            icon: 'briefcase-solid.svg',
            description: 'Business Sales, mergers & Acquisitions, and Investments',
            formComponentName: 'Mergers',
        },

        {
            name: 'General',
            value: 'GENERAL_GOODS_SERVICES',
            icon: 'shopping-bag-solid.svg',
            description: "General goods or services that doesn't fit in the other categories",
            formComponentName: 'General',
        },
        {
            name: 'Rental Deposits',
            value: 'RENTAL',
            icon: 'umbrella-beach-solid.svg',
            description: 'Holiday & Rental Deposits',
            formComponentName: '',
            disabled: true,
            disabledText: 'Coming Soon',
        },
    ],
    transactionTypes: [
        {
            name: 'Normal',
            value: 'Single',
            icon: 'check-circle-solid.svg',
            description: 'A single payment will be made to the seller on the completion of the transaction',
            disabled: false,
        },
        {
            name: 'Milestone',
            value: 'Milestone',
            icon: 'tasks-solid.svg',
            description: 'Payments will be made on the completion of milestones',
            disabled: false,
        },
        {
            name: 'Drawdown',
            value: 'Drawdown',
            icon: 'sort-amount-down-solid.svg',
            description: 'Amounts can be withdrawn from the escrow account on the approval by the buyer',
            disabled: true,
            disabledText: 'Coming Soon',
        },
    ],
    transactionTypesFuelMining: [
        {
            name: 'Normal',
            value: 'Single',
            icon: 'check-circle-solid.svg',
            description: 'A single payment will be made to the seller on the completion of the transaction',
            disabled: false,
        },
        {
            name: 'Multiple Loads',
            value: 'Milestone',
            icon: 'tasks-solid.svg',
            description: 'Payment will be made on the completion of each load',
            disabled: false,
        },
        {
            name: 'Drawdown',
            value: 'Drawdown',
            icon: 'sort-amount-down-solid.svg',
            description: 'Amounts can be withdrawn from the escrow account on the approval by the buyer',
            disabled: true,
            disabledText: 'Coming Soon',
        },
    ],
    feeAllocationWithAgent: [
        {
            value: 'Agent pays',
            key: 'AGENT',
        },
        {
            value: 'Buyer pays',
            key: 'BUYER',
        },
        {
            value: 'Seller pays',
            key: 'SELLER',
        },
        {
            value: 'Buyer / Seller split',
            key: 'BUYER_SELLER',
        },
        {
            value: 'Buyer / Agent split',
            key: 'BUYER_AGENT',
        },
        {
            value: 'Seller / Agent split',
            key: 'SELLER_AGENT',
        },
        {
            value: 'Buyer / Seller / Agent split',
            key: 'BUYER_SELLER_AGENT',
        },
    ],
    feeAllocationWithoutAgent: [
        {
            value: 'Buyer pays',
            key: 'BUYER',
        },
        {
            value: 'Seller pays',
            key: 'SELLER',
        },
        {
            value: 'Buyer / Seller split',
            key: 'BUYER_SELLER',
        },
    ],
}
