<template>
    <div>
        <div class="uk-flex uk-flex-between uk-flex-wrap uk-margin">
            <div><h4>Files included for this transaction</h4></div>
            <div>
                <GL-Button
                    v-if="showEditButton"
                    text="Edit Files"
                    type="primary-outlined"
                    @click="$router.push(`/transaction/${transaction.id}/documents`)"
                />
            </div>
        </div>
        <div v-if="!transaction.files || transaction.files.length === 0">
            No files have been uploaded to this transaction.
        </div>
        <div v-if="transaction && transaction.files && transaction.files.length !== 0">
            <div
                class="outline uk-padding-small uk-margin-small"
                v-for="(file, index) in transaction.files"
                :key="index"
            >
                <div uk-grid>
                    <div class="uk-width-expand">{{ file.filename }}</div>
                    <div>
                        <a :href="file.uri" class="clickable" download>
                            <GL-Icon path="download-solid.svg" :size="1.25" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!--        <div class="uk-text-right uk-margin-top" v-if="showEditButton">-->
        <!--            <GL-Button-->
        <!--                text="edit files"-->
        <!--                type="primary uk-button-small"-->
        <!--                @click="navigateEditPage"-->
        <!--                loading-text="Saving"-->
        <!--            />-->
        <!--        </div>-->
    </div>
</template>
<script>
import mixinTransaction from '@/modules/common/mixin-transaction'

export default {
    mixins: [mixinTransaction],
    data() {
        return {
            notEditableStates: ['CANCELED', 'DECLINED', 'SUSPENDED', 'ADMIN_SUSPENDED', 'ADMIN_CANCELLED'],
        }
    },
    computed: {
        showEditButton() {
            if (
                this.transaction.metadata.activeParty.role === 'READ_ONLY' ||
                this.notEditableStates.includes(this.transaction.state)
            ) {
                return false
            }
            return !(this.$store.getters['txCreate/editMode'] || this.transaction.state === 'CREATED')
        },
    },
    mounted() {
        this.$getSeenService().markTransactionSeen(this.transaction)
    },
}
</script>

<style scoped>
.outline {
    border-style: solid;
    border-width: 0.08rem;
    border-radius: 0.5rem;
    border-color: #ddd;
}

.clickable {
    color: #676767;
}

.clickable:hover {
    cursor: pointer;
    color: #999;
}
</style>
