export default [
    {
        path: '/transaction/:id',
        name: 'Transaction',
        component: () => import('./views/transaction.vue'),
    },
    {
        path: '/transaction/:id/documents',
        name: 'DocumentsEdit',
        component: () => import('./views/transaction-documents-edit'),
    },
    {
        path: '/transaction/:id/details',
        name: 'DetailsEdit',
        component: () => import('./views/transaction-details-edit'),
    },
    {
        path: '/transaction/:id/parties',
        name: 'PartiesEdit',
        component: () => import('./views/transaction-parties-edit'),
    },
    {
        path: '/transaction/:id/delivery',
        name: 'DeliveryEdit',
        component: () => import('./views/transaction-delivery-edit'),
    },
    {
        path: '/ozow/success',
        name: 'OzowSuccess',
        component: () => import('./views/ozow-success.vue'),
    },
    {
        path: '/letterofcomfort/:code',
        name: 'LetterOfComfort',
        component: () => import('./views/letter-of-comfort'),
    },
]
