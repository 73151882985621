const delay = retryCount => new Promise(resolve => setTimeout(resolve, (retryCount * 30) ** 2))

export async function retryOrganizationValidate(request, retryCount = 0) {
    if (retryCount) {
        await delay(retryCount)
    }
    const response = await request()
    if (response.data.organizationValidate.status === 'PENDING') {
        return retryOrganizationValidate(request, retryCount + 1)
    } else if (response.data.organizationValidate.status === 'FAILED') {
        throw {
            displayMessage:
                "For some reason we were not able to verify your organisation's banking details. Please confirm that the details are correct and try again. If the verification still fails after retrying please contact support",
        }
    } else {
        return response
    }
}
