<template>
    <v-tour name="postRegistrationTour" :steps="steps" :callbacks="tourCallbacks">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step
                    v-for="(step, index) of tour.steps"
                    v-if="tour.currentStep === index"
                    :key="index"
                    :step="step"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                >
                    <template>
                        <div slot="header">
                            <div class="uk-position-right step-number">{{ index + 1 }}/{{ tour.steps.length }}</div>
                            <div v-if="step.header" class="v-step__header">
                                <strong>{{ step.header.title }}</strong>
                            </div>
                        </div>
                    </template>
                    <template v-if="tour.currentStep === tour.steps.length - 1">
                        <div slot="actions">
                            <button
                                class="v-step__button"
                                data-id="link_content_content link"
                                data-text="tour | previous button click"
                                @click="tour.previousStep"
                            >
                                Previous
                            </button>
                            <button
                                class="v-step__button"
                                data-id="link_content_content link"
                                data-text="tour | close button click"
                                @click="tour.stop"
                            >
                                Close
                            </button>
                            <button
                                class="v-step__button"
                                data-id="link_content_content link"
                                data-text="tour | my account button click"
                                @click="goToMyProfile(tour)"
                            >
                                My Profile
                            </button>
                        </div>
                    </template>
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>
<script>
export default {
    name: 'Tour',
    props: {
        showProfileLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tourCallbacks: {
                onFinish: this.removeTourParam,
                onSkip: this.removeTourParam,
            },
            steps: [
                {
                    target: '.home-tour-step-1',
                    header: {
                        title: 'Welcome to Escrow',
                    },
                    content:
                        'This is your home page where you can transact safely and securely. Let’s take a look around',
                    params: {
                        highlight: true,
                    },
                },
                {
                    target: '.home-tour-step-2',
                    content: 'Before you can transact, you will need to complete your details here',
                    params: {
                        highlight: true,
                    },
                },
                {
                    target: '.home-tour-step-3',
                    content:
                        "If you want to transact in your company's capacity, complete your general details on the My Profile page. Then click here to get your company registered",
                    params: {
                        highlight: true,
                    },
                },
                {
                    target: '.home-tour-step-4',
                    content:
                        'You can create your first transaction here. Grab the other party’s email address and tell them to expect an Escrow transaction',
                    params: {
                        placement: 'top',
                        highlight: true,
                        enableScrolling: false,
                    },
                    offset: -400,
                },
                {
                    target: '.home-tour-step-5',
                    content:
                        'This is where you can fund your Escrow account via EFT. These funds can be allocated to a transaction later on',
                    params: {
                        placement: 'top',
                        highlight: true,
                        enableScrolling: false,
                    },
                    offset: -400,
                },
                {
                    target: '.home-tour-step-6',
                    content:
                        'This shows you the value of the funds in your Escrow account, funds in Escrow that are allocated to transactions you are involved in and funds that are payable to you',
                    params: {
                        placement: 'top',
                        highlight: true,
                    },
                },
                {
                    target: '.home-tour-step-7',
                    content: 'View your current, completed and cancelled transactions here',
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top',
                    },
                },
                {
                    target: '.home-tour-step-8',
                    content:
                        'Non-Standard Bank customers can only transact as a SELLER. <br/>Why not take 10 minutes to open a Standard Bank MyMo account',
                    params: {
                        highlight: true,
                        placement: 'top',
                    },
                },
                {
                    target: '.home-tour-step-9',
                    content: 'But first things first, we need to verify your profile before you can begin using Escrow',
                    params: {
                        highlight: true,
                    },
                },
            ],
        }
    },
    methods: {
        goToMyProfile(tour) {
            tour.stop()
            this.$router.push('/profile')
        },
        removeTourParam() {
            this.$router.push('/')
        },
    },
}
</script>
