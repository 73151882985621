<template>
    <div class="uk-flex uk-flex-center uk-margin-bottom uk-animation-slide-top-small">
        <div class="uk-card uk-card-default uk-card-body uk-width-5-6@m">
            <div class="uk-flex uk-flex-between">
                <div>
                    <div class="uk-label uk-label-success">Milestone {{ index + 1 }}</div>
                </div>
                <a @click="$store.commit('txCreate/removeAllocation', index)" v-if="index > 1" class="uk-icon-button"
                    >X</a
                >
            </div>
            <div uk-grid>
                <GL-TextInput
                    class="uk-width-1-2@s uk-margin-top"
                    label="Give your milestone a name"
                    v-model="title"
                    :error-messages="validation.requiredField(title)"
                />
                <GL-CurrencyInput
                    class="uk-width-1-2@s uk-margin-top"
                    label="Milestone amount"
                    v-model="value"
                    :error-messages="validation.allocationValue(value)"
                />
                <GL-TextAreaInput
                    class="uk-width-1-1 uk-margin-top"
                    label="Provide a detailed description for this milestone"
                    v-model="description"
                    :error-messages="validation.requiredField(description)"
                />
                <GL-NumberInput
                    class="uk-width-1-2@s uk-margin-top"
                    label="Number of days for delivery"
                    v-model="daysToInspect"
                    :error-messages="validation.requiredField(daysToInspect)"
                />
                <GL-NumberInput
                    class="uk-width-1-2@s uk-margin-top"
                    label="Number of days for inspection"
                    v-model="daysToDeliver"
                    :error-messages="validation.requiredField(daysToDeliver)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import validation from '@/scripts/transaction-create-field-validation'

export default {
    name: 'MilestoneCard',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            validation,
        }
    },
    computed: {
        title: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].title
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'title', value: v })
            },
        },
        value: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].value
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'value', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].description
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'description', value: v })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'daysToDeliver', value: v })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'daysToInspect', value: v })
            },
        },
    },
    methods: {
        removeAllocation() {},
    },
}
</script>
