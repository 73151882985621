<template>
    <div>
        <p>
            Should your request for authorisation be declined, you will have an opportunity to reset the authorisation
            request.
        </p>
        <GL-Alert v-if="directorsCheck.state === 'DECLINED'" type="danger">
            Your request for authorisation to transact on Escrow on your company's behalf has been declined.
        </GL-Alert>
        <h4>Directors List:</h4>
        <p v-if="directorsCheck.status === 'PENDING'">
            Approvals are due for <strong> {{ directorsCheck.completeBy }} </strong>
        </p>
        <div v-for="(director, index) in directorsCheck.directors" :key="index" class="uk-margin-bottom">
            <div class="uk-flex uk-flex-between uk-flex-wrap">
                <div>
                    <h5>{{ director.name }}</h5>
                    <div><strong>Email:</strong> {{ director.email }}</div>
                    <div><strong>Mobile:</strong> {{ director.mobile }}</div>
                    <div><strong>Signatory:</strong> {{ director.signatory ? 'Yes' : 'No' }}</div>
                </div>
                <div>
                    <div v-if="loadingIds[director.id]">
                        <GL-Spinner />
                    </div>
                </div>
            </div>
            <GL-Alert v-if="director.verified === true && director.signatory" type="success">
                {{ director.name }} has confirmed that you are authorized to transact on Escrow on the business's behalf
            </GL-Alert>
            <GL-Alert v-if="!director.verifiedAt && director.verified === false && director.signatory" type="warning">
                {{ director.name }} has been requested to confirm that you are authorized to transact on Escrow on the
                business's behalf
            </GL-Alert>
            <GL-Alert v-if="director.verifiedAt && director.verified === false && director.signatory" type="danger">
                {{ director.name }} has declined your authorisation to transact on Escrow on the business's behalf
            </GL-Alert>
        </div>
        <GL-ErrorAlert :errorMessage="errormessageResendAll" />
    </div>
</template>
<script>
import Vue from 'vue'
export default {
    data() {
        return {
            loadingIds: {},
            errormessageResendAll: '',
            loadingResendAll: false,
            loadingReset: false,
        }
    },
    props: {
        directorsCheck: {
            type: Object,
        },
    },
    mounted() {
        for (const director of this.directorsCheck.directors) {
            Vue.set(this.loadingIds, director.id, false)
        }
    },
    computed: {
        showResendButton() {
            if (this.directorsCheck.state === 'SENT' || this.directorsCheck.state === 'CREATED' || this.directorsCheck.state === 'DECLINED') {
                for (const director of this.directorsCheck.directors) {
                    if (director.verifiedAt && !director.verified) {
                        return false
                    }
                }
                return true
            }
            return false
        },
    },
    methods: {
        async resendDirector(id) {
            try {
                this.loadingIds[id] = true
                await this.$getApi().sbsa.directorSendNotification(id)
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingIds[id] = false
            }
        },
        async resetDirector(id) {
            try {
                this.loadingIds[id] = true
                await this.$getApi().sbsa.directorSendNotification(id)
                await this.$store.dispatch('organization/organizationFetch')
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingIds[id] = false
            }
        },
        async resetConfirmationRequest() {
            try {
                this.loadingReset = true
                await this.$getApi().sbsa.directorCheckReset(this.directorsCheck.id)
                if (this.directorsCheck.confirmationFor === 'TRANSACTION') {
                    await this.$store.dispatch('transaction/transactionRefetch')
                } else if (this.directorsCheck.confirmationFor === 'ORGANIZATION') {
                    await this.$store.dispatch('organization/organizationFetch')
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingReset = false
            }
        },
        async resendAllDirectors() {
            try {
                this.errormessageResendAll = ''
                this.loadingResendAll = true
                for (let director of this.directorsCheck.directors) {
                    await this.$getApi().sbsa.directorSendNotification(director.id)
                }
            } catch (error) {
                this.errormessageResendAll = this.$handleError(error)
            } finally {
                this.loadingResendAll = false
            }
        },
    },
}
</script>
