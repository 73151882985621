<template>
    <div>
        <h4>Confidentiality is enabled</h4>
        <div v-if="transaction.privacy === 'ALL'">All details are private</div>
        <div v-if="transaction.privacy === 'DETAILS'">Party details are private</div>
        <div v-if="transaction.privacy === 'CALCULATIONS'">Fee's and costs are private</div>
    </div>
</template>
<script>
export default {
    props: {
        /*
            Transaction object is expected to have :
            - Privacy
        */
        transaction: {
            type: Object,
            required: true,
        },
    },
}
</script>
