<template>
    <div>
        <div class="uk-margin-bottom uk-text-center">
            <h4 class="uk-text-secondary">TRANSACTION PARTIES</h4>
        </div>
        <div class="uk-flex uk-flex-center">
            <div class="uk-card uk-card-default uk-card-body uk-width-5-6@m">
                <div class="uk-text-center">
                    <p class="uk-padding-small">
                        Please fill in the other {{ counterParty.role }}'s email address below. We require this to send
                        an invitation to the {{ counterParty.role }} who can then accept your transaction
                    </p>
                </div>
                <div class="uk-flex uk-flex-center">
                    <div class="uk-width-1-2@m">
                        <GL-TextInput label="email" v-model.trim="email" :error-messages="validateEmail" />
                        <GL-TextInput
                            class="uk-margin-top"
                            label="confirm email"
                            v-model.trim="emailConfirmation"
                            :error-messages="validateEmailConfirmation"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="uk-margin-remove-top" uk-grid>-->
        <!--            <div class="uk-width-expand@m uk-visible@m" />-->
        <!--            <div class="uk-width-5-6@m uk-margin-small-right uk-margin-small-left">-->
        <!--                <OwnerCard v-if="!editMode" />-->
        <!--                <div v-for="(party, index) in parties" :key="index" class="uk-margin-small">-->
        <!--                    <PartiesCard v-if="editMode || index > 0" :index="index" />-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="uk-width-expand@m uk-visible@m" />-->
        <!--        </div>-->
        <!-- <div class="uk-text-center uk-padding" :class="{ 'uk-padding-remove-bottom': editMode }">
            <GL-Button text="add party" @click="addParty" type="primary-outlined" />
        </div> -->
        <TransactionInformation v-if="editMode" />
        <CreateTxNavButtons
            :disable-next="!allowProgress"
            anaylticsDataId="link_content_create transaction"
            anayliticsNextDataText="step 2| next button click"
            anayliticsBackDataText="step 2| back button click"
            anayliticsCancelDataText="step 2| cancel button click"
        />
    </div>
</template>

<script>
import CreateTxNavButtons from '../common/create-transaction-navigation'
import validation from '@/scripts/validation/validation'
import TransactionInformation from '../transaction-information/index'
import fieldValidation from '@/scripts/transaction-create-field-validation'

export default {
    components: { TransactionInformation, CreateTxNavButtons },
    name: 'Parties',
    data() {
        return {
            errors: {},
        }
    },
    mounted() {
        this.$setVirtualPageView(
            'home',
            'home:escrow create transaction:step 2 parties',
            'home:escrow create transaction',
            'home:escrow create transaction:step 2 parties',
        )
    },
    computed: {
        counterParty() {
            return this.$store.getters['txCreate/counterParty']
        },
        counterPartyIndex() {
            return this.$store.getters['txCreate/counterPartyIndex']
        },
        email: {
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.counterPartyIndex, key: 'email', value: v })
            },
            get() {
                return this.$store.state.txCreate.parties.parties[this.counterPartyIndex].email
            },
        },
        emailConfirmation: {
            get() {
                return this.$store.state.txCreate.parties.emailConfirmations[this.counterPartyIndex]
            },
            set(v) {
                this.$store.commit('txCreate/setEmailConfirmation', { index: this.counterPartyIndex, value: v })
            },
        },
        validateEmail() {
            return validation.validate(this.email, [
                validation.list.required('Please provide an email address'),
                validation.list.email('Invalid email'),
                validation.list.unique(
                    'Cannot match your email address',
                    this.otherPartyEmails(this.counterPartyIndex),
                ),
            ])
        },
        validateEmailConfirmation() {
            return validation.validate(this.emailConfirmation, [
                validation.list.required('Please confirm the email address'),
                validation.list.same_as('Emails do not match', this.email),
            ])
        },
        parties() {
            return this.$store.state.txCreate.parties.parties
        },
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        partyFeesComplete() {
            for (const party of this.parties) {
                if (party.role !== 'BUYER' && party.role !== 'SELLER') {
                    if (party.role === 'AGENT') {
                        if (!(party.fee && party.feeType)) {
                            return false
                        }
                    } else {
                        if (!(party.fee && party.feeType && party.feeAllocation)) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        allowProgress() {
            for (let i = 1; i < this.parties.length; i += 1) {
                const test = validation.validate(this.parties[i].email, [
                    validation.list.required('Please provide a email address for this party'),
                    validation.list.unique('Must be a unique email', this.otherPartyEmails(i)),
                    validation.list.email('Invalid email'),
                    validation.list.same_as(
                        'Emails do not match',
                        this.$store.state.txCreate.parties.emailConfirmations[i],
                    ),
                ])
                if (!this.parties[i].email || test.length > 0 || !this.parties[i].role) {
                    return false
                }
            }
            if (this.editMode) {
                fieldValidation.partyDetailsComplete()
            }
            return true
        },
    },
    methods: {
        otherPartyEmails(myIndex) {
            const otherParties = this.$store.state.txCreate.parties.parties.filter((party, index) => index !== myIndex)
            return otherParties.map(({ email }) => email)
        },
        addParty() {
            this.$store.commit('txCreate/addParty')
        },
        removeParty(index) {
            this.$store.commit('txCreate/removeParty', index)
        },
    },
}
</script>
