export default [
    {
        path: '/business/add',
        name: 'BusinessAdd',
        component: () => import('./views/business-add.vue'),
    },
    {
        path: '/business/verification',
        name: 'BusinessVerification',
        component: () => import('./views/business-verification.vue'),
    },
    {
        path: '/business/verification/success',
        name: 'BusinessVerificationSuccess',
        component: () => import('./views/business-verification-success.vue'),
    },
]

