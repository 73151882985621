import store from '@/store'

export default {
    hasSeenHome() {
        return store.state.user.user.settings.seenTour.home
    },
    markHomeSeen(value) {
        store.commit('user/setUserField', { key: 'settings|seenTour|home', value: value })
        store.dispatch('user/userSettingsUpdate')
    },

    hasSeenProfile() {
        return store.state.user.user.settings.seenTour.profile
    },
    markProfileSeen(value) {
        store.commit('user/setUserField', { key: 'settings|seenTour|profile', value: value })
        store.dispatch('user/userSettingsUpdate')
    },

    hasSeenCreate() {
        return store.state.user.user.settings.seenTour.create
    },
    markCreateSeen(value) {
        store.commit('user/setUserField', { key: 'settings|seenTour|create', value: value })
        store.dispatch('user/userSettingsUpdate')
    },
}
