<template>
    <div class="unselectable">
        <label>
            <input class="uk-checkbox" type="checkbox" :disabled="disabled" v-model="checkedComputed" />
            {{ text }}
            <slot name="label-suffix"> </slot>
        </label>
    </div>
</template>

<script>
export default {
    name: 'CheckboxInput',
    props: {
        text: {
            type: String,
            default: '',
        },
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        checkedComputed: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>
