<template>
    <div class="uk-margin-large-top uk-margin-bottom uk-width-1-1">
        <div class="uk-flex uk-flex-center uk-flex-wrap">
            <GL-Button
                :type="calculationDataReady ? 'primary-outlined' : 'primary'"
                :text="calculationVisible ? 'Hide Transaction Calculation' : 'Show Transaction Calculation'"
                :disabled="!calculationDataReady"
                disabledText="Show Transaction Calculation"
                @click="calculationVisible = !calculationVisible"
                class="uk-width-1-1 uk-width-auto@s"
                :class="{
                    'uk-margin-small-right': $screenLarger('small'),
                    'uk-margin-small-bottom': $screenSmaller('small'),
                }"
            />
            <GL-Button
                v-if="hasMilestones"
                :type="calculationDataReady ? 'primary-outlined' : 'primary'"
                :text="milestoneSummaryVisible ? `Hide Milestone Summary` : `Show Milestone Summary`"
                :disabled="!calculationDataReady"
                disabledText="Show Milestone Summary"
                class="uk-width-1-1 uk-width-auto@s"
                @click="milestoneSummaryVisible = !milestoneSummaryVisible"
            />
        </div>
        <div>
            <TransactionCalculation :calculation-visible="calculationVisible" />
            <MilestoneSummary :milestone-summary-visible="milestoneSummaryVisible" />
        </div>
    </div>
</template>

<script>
import TransactionCalculation from './transaction-calculation'
import MilestoneSummary from './milestone-summary'
export default {
    name: 'TransactionInformation',
    components: { MilestoneSummary, TransactionCalculation },
    data() {
        return {
            calculationVisible: false,
            milestoneSummaryVisible: false,
        }
    },
    computed: {
        calculationDataReady() {
            return this.$store.getters['txCreate/calculationDataReady']
        },
        industry() {
            return this.$store.state.txCreate.transaction.transaction.industry
        },
        allocationNames() {
            if (this.industry === 'FUEL' || this.industry === 'MINING') {
                return 'Load'
            }
            return 'Milestone'
        },
        hasMilestones() {
            return this.$store.state.txCreate.transaction.transaction.workflow === 'MILESTONE'
        },
    },
    watch: {
        calculationVisible() {
            if (this.calculationVisible) {
                this.milestoneSummaryVisible = false
            }
        },
        milestoneSummaryVisible() {
            if (this.milestoneSummaryVisible) {
                this.calculationVisible = false
            }
        },
    },
}
</script>
