<template>
    <InputBase
        :right-align-label="true"
        :label="label"
        :tooltip="tooltip"
        :error-messages="errorMessages"
        :dirty="dirty"
    >
        <input
            :name="name"
            :value="finalValue"
            :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty }"
            type="text"
            autocomplete="off"
            class="uk-input uk-display-inline right-align"
            :disabled="disabled"
            @focus="focus($event)"
            @blur="blur($event)"
            @input="input($event)"
        />
    </InputBase>
</template>

<script>
// :auto-decimal-mode="false"
// :distraction-free="false"

import InputBase from './input-base'
export default {
    name: 'GL-CurrencyInput',
    components: {
        InputBase,
    },
    data() {
        return {
            dirty: false,
            focussed: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            required: true,
        },
        tooltip: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
    },
    computed: {
        finalValue() {
            if (!this.value || this.value === 0 || this.value === '0') {
                return null
            }
            if (this.focussed) {
                return this.value
                // TODO: When the number ends on a zero fix the number to 2 decimals
                // if (typeof this.value === 'number') {
                //     result = this.value.toFixed(2)
                // } else {
                //     result = this.value
                // }
            } else {
                const split = this.value
                    .toString()
                    .replace(/[^0-9.]/g, '')
                    .split('.')
                if (split.length > 2) {
                    return null
                }

                return 'R' + this.$getFilters().formatCurrency(this.value)
            }
        },
    },
    methods: {
        input(event) {
            if (event && event.target) {
                if (!event.target.value) {
                    event.target.value = 0
                }
                event.target.value = event.target.value.toString()
                event.target.value = event.target.value.replace(/[^0-9.]/g, '')
                if (event.target.value !== '') {
                    this.dirty = true
                    this.$emit('input', parseFloat(event.target.value))
                } else {
                    this.$emit('input', event.target.value)
                }
            }
        },
        focus() {
            this.focussed = true
        },
        blur() {
            this.focussed = false
        },
    },
}
</script>
