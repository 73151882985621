import store from '@/store'

export const steps = [
    {
        target: '.create-tour-step-1',
        content:
            'Good job, you have decided to create a transaction. Let’s show you how to set up your first transaction.',
        params: {
            // placement: 'right',
            highlight: true,
        },
    },
    {
        target: '.create-tour-step-2',
        content: 'First, select your role in this transaction.',
        params: {
            placement: 'top',
            highlight: true,
        },
    },
    {
        target: '.create-tour-step-3',
        content:
            'Select the industry that this transaction applies to. If you don’t see a category that fits your transaction, just select GENERAL.',
        params: {
            placement: 'top',
            highlight: true,
        },
    },
    {
        target: '.create-tour-step-4',
        content:
            'Select the type of transaction. The most common transaction type are single payments where the buyer pays Escrow, and then Escrow makes one payment to the seller (or agent if applicable). Milestone payments are partial payments that get released to the seller on the completion of agreed milestones.',
        params: {
            highlight: true,
            placement: 'top',
        },
    },
    {
        target: '.create-tour-step-5',
        content:
            'You will then be able to add the other party to your deal. A transaction can have more than two parties… just add them here. Please make sure that you have their email address ready.',
        params: {
            highlight: true,
        },
        offset: -400,
    },
    {
        target: '.create-tour-step-6',
        content:
            'Here, you will be able to describe your transaction in detail such as the description of the goods or services, and the price.',
        params: {
            highlight: true,
        },
        offset: -400,
    },
    {
        target: '.create-tour-step-7',
        content:
            'Next, you will be able to specify the delivery location. Arranging private delivery? No problem, that option is also available.',
        params: {
            highlight: true,
        },
        offset: -400,
    },
    {
        target: '.create-tour-step-8',
        content:
            'Add supporting documents to your transaction. This can be almost anything such as agreements, contracts, photos, invoices etc.',
        params: {
            highlight: true,
        },
        offset: -400,
    },
    {
        target: '.create-tour-step-9',
        content:
            'Finally, the last step is for you to review your transaction before we send it off to the other party/s. They will then be able to accept your transaction on Escrow.',
        params: {
            highlight: true,
        },
        offset: -400,
    },
]

export const callbacks = {
    onStart: () => {
        store.commit('tour/setTourRunning', true)
    },
    onStop: () => {
        store.commit('tour/setTourRunning', false)
    },
}
