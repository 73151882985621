<template>
    <div class="uk-flex uk-flex-center">
        <div class="uk-card uk-card-default uk-card-body uk-width-5-6@m">
            <form data-frmcontainer="Describe Create Transaction" @submit.prevent uk-grid>
                <GL-TextInput
                    class="uk-width-1-1"
                    label="Give your transaction a name"
                    v-model="title"
                    :error-messages="validation.requiredField(title)"
                />
                <GL-SelectInput
                    class="uk-width-1-2@s uk-margin-top"
                    v-model="industry"
                    label="Type of transaction"
                    :option-list="industries"
                    :error-messages="validation.requiredField(industry)"
                />
                <GL-SelectInput
                    class="uk-width-1-2@s uk-margin-top"
                    v-model="feeAllocation"
                    label="Who Pays The Escrow Fee?"
                    :option-list="feeAllocationOptions"
                    :error-messages="validation.requiredField(feeAllocation)"
                />
                <GL-TextAreaInput
                    class="uk-width-1-1 uk-margin-top"
                    label="Provide a detailed description, include as much detail as possible"
                    tooltip="Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                    v-model="description"
                    :error-messages="validation.requiredField(description)"
                />
            </form>
        </div>
    </div>
</template>

<script>
import validation from '@/scripts/transaction-create-field-validation'
import { industries } from '@/constants/constants-industries'
export default {
    name: 'MainDetailsForm',
    data() {
        return {
            validation,
        }
    },
    computed: {
        title: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.title
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.description
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'description', value: v })
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'feeAllocation', value: v })
            },
        },
        industry: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.industry
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'industry', value: v })
            },
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        industries() {
            return industries.map(({ value, name }) => {
                return { key: value, value: name }
            })
        },
    },
}
</script>
